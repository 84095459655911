import { CalendarTranslatorService } from './../services/calendar-translator.service';
import { Injectable, Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LOGGER, LogLevelEnum } from '@app/services/logger.service';
import { ConfigService } from '@app/services/config.service';
import { AppConfig } from '@app/models/app-config.model';
import { of } from 'rxjs';
import { AuthenticationService } from '@app/services/authentication.service';
import { Session } from '@app/app.session';
import { Router } from '@angular/router';

@Injectable()
export class CoreInitializer {
  constructor(
    private injector: Injector,
    private calendarTranslatorService: CalendarTranslatorService,
    public session: Session
  ) {
  }

  public init(): Promise<void> {
    console.log('Initializing application');

    // Retrieve services via the Injector
    const configService: ConfigService = this.injector.get(ConfigService);
    let authService: AuthenticationService;

    return configService.loadConfig()
      .then(async (config: AppConfig) => {
        authService = this.injector.get(AuthenticationService);

        // Logging
        LOGGER.clientLogLevel = LogLevelEnum.DEBUG;
        LOGGER.serverLogLevel = LogLevelEnum.ERROR;

        // Retrieve services (which need the config to be loaded) via the Injector
        const translate = this.injector.get(TranslateService);

        // Translation
        await translate.addLangs(config.languages);
        await translate.setDefaultLang(config.lang);
        const translations = await translate.use(config.lang);

        return of('Application initialized').toPromise();
      })
      .then((info) => {
        LOGGER.info(info);
      })
      .then(async () => {
        authService = this.injector.get(AuthenticationService);

        await authService.loadAuthentication().catch(err => {
          authService.unAuthenticated();
        });

        if (authService && authService.isAuthenticated()) {
          if (this.session.connectedUser.languageCode) {
            const translate = this.injector.get(TranslateService);

            await translate.setDefaultLang(this.session.connectedUser.languageCode);
            const translations = await translate.use(this.session.connectedUser.languageCode);
          }
          this.calendarTranslatorService.loadAndStoreSettings();
        } else {
          authService.unAuthenticated();
        }
      })
      .catch(err => {
        console.log('core initializer init error : ' + err);
      });
  }
}
