import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { OrderDetail } from '@app/models/models';
import { debug } from 'util';
import { GMap } from 'primeng/primeng';

declare var google: any;

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.scss']
})
export class OrdersDetailsComponent implements OnInit {

  @Input()
  orderDetail: OrderDetail;
  @Input()
  total: string;

  orderDeliveryOptions: any;
  orderDeliveryOverlays: any;

  siteDeliveryOptions: any;
  siteDeliveryOverlays: any;

  apiKey = 'AIzaSyBT2U9HcdUQsbtzyGfcOJJUuYKYTxrIN30';
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.orderDeliveryOptions = {};
    this.orderDeliveryOverlays = {};

    this.siteDeliveryOptions = {};
    this.siteDeliveryOverlays = {};
  }

  public setMaps(orderDetail: OrderDetail) {
    if (orderDetail) {
      if (orderDetail.orderDeliveryAddress1) {
        const address1 = orderDetail.orderDeliveryAddress1 + '+'
        + orderDetail.orderDeliveryLocality + '+'
         + orderDetail.orderDeliveryPostCode + '+'
          + orderDetail.orderDeliveryCountry;


       this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address1.replace(' ', '+') +
                      '&key=' + this.apiKey).subscribe(r => {
         const res = <any> r;
         if (res.status === 'OK') {
           const lat = <number> res.results[0].geometry.location.lat;
           const long = <number> res.results[0].geometry.location.lng;

           this.orderDeliveryOptions = {
             center: {lat: lat, lng: long},
             zoom: 15
           };
           this.orderDeliveryOverlays = [
             new google.maps.Marker({position: {lat: lat, lng: long}})
           ];
         }
       }, (err) => {
        console.log(err);
      });
      }

      if (orderDetail.siteDeliveryAddress1) {
        const address2 = orderDetail.siteDeliveryAddress1 + '+'
        + orderDetail.siteDeliveryLocality + '+'
         + orderDetail.siteDeliveryPostCode + '+'
          + orderDetail.siteDeliveryCountry;

       this.http.get('https://maps.googleapis.com/maps/api/geocode/json?address=' + address2.replace(' ', '+') +
        '&key=' + this.apiKey).subscribe(r => {
         const res = <any> r;
         if (res.status === 'OK') {
           const lat = +res.results[0].geometry.location.lat;
           const long = +res.results[0].geometry.location.lng;
           this.siteDeliveryOptions = {
             center: {lat: lat, lng: long},
             zoom: 15
           };
           this.siteDeliveryOverlays = [
             new google.maps.Marker({position: {lat: lat, lng: long}})
           ];
         }
       }, (err) => {
        console.log(err);
      });
      }
    }
  }
}
