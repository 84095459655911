import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <app-layout></app-layout>`
})
export class AppComponent {
  constructor() {
  }

}
