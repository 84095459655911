/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import {Inject, Injectable, Optional} from '@angular/core';
import {
  HttpClient, HttpHeaders,
  HttpResponse, HttpEvent
} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';

import {PeriodStatus} from '@app/models/periodStatus';

import {BASE_PATH} from '@app/core/variables';
import {Configuration} from '@app/core/configuration';
import {AppConfig} from '@app/models/app-config.model';


@Injectable({providedIn: 'root'})
export class PeriodStatusService {

  protected basePath: string = this.appConfig.configServer.serverPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient,
              @Optional() @Inject(BASE_PATH) basePath: string,
              @Optional() configuration: Configuration,
              public appConfig: AppConfig) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Gets all period statuses asynchronously.
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public periodStatusGetAllPeriodStatusesAsync(observe?: 'body', reportProgress?: boolean):
    Observable<Array<PeriodStatus>>;
  public periodStatusGetAllPeriodStatusesAsync(observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<Array<PeriodStatus>>>;
  public periodStatusGetAllPeriodStatusesAsync(observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<Array<PeriodStatus>>>;
  public periodStatusGetAllPeriodStatusesAsync(observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<PeriodStatus>>(`${this.basePath}/api/v1/periodstatuses`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
