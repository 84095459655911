export class Images {
  addImage: '../assets/images/ajout.png';
  searchImage: '../assets/images/loupe.png';
  deleteImage: '../assets/images/supprimer.png';
  printImage: '../assets/images/print-temp.png';
  cartImage: '../assets/images/commande2.png';
  moreVertImage: '../assets/images/more-vert.png';
  simpleGlass: '../assets/images/vitre_simple.png';
  doubleGlass: '../assets/images/doublie_vitrage.png';
  tripleGlass: '../assets/images/triple_vitrage.png';
  quadraGlass: '../assets/images/quadruple_vitrage.png';
  accessory: '../assets/images/accessoires.png';
  leftArrowImage: '../assets/images/left-arrow.png';
  rightArrowImage: '../assets/images/right-arrow.png';
  sun: 'sun';
  svg: '.svg';
  testRecap1: '../assets/images/test-recap-img1.png';
  testRecap2: '../assets/images/test-recap-img2.png';
  excelImage: '../assets/images/excel.png';
}
