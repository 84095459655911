import { Observable } from 'rxjs';
import { Session } from '@app/app.session';
import { Injectable } from '@angular/core';
import { CustomerService } from './customer.service';
import { ComplaintService } from './complaint.service';
import { SavFilters } from '@app/models/filters/savFilters';
import { Customer } from '@app/models/models';
import { TranslateService } from '@ngx-translate/core';
import { SearchClaimRequest } from '@app/models/searchClaimRequest';
import { ClaimService } from '@app/services/claim.service';

@Injectable({ providedIn: 'root' })
export class SavTableService {

  constructor( public customerService: CustomerService,
               public session: Session,
               private complaintService: ComplaintService,
               private claimService: ClaimService,
               public translateService: TranslateService) {
               }

  public savFilters: SavFilters;
  public pageSize  = 10;
  public totalRecords: number;

  public searchPage(searchClaimRequest?: SearchClaimRequest,
                    pageNumber?: number,
                    loading?: Boolean,
                    customers?: Customer[],
                    canInit?: Boolean
                    ): Observable<any> {

    // init savFilters
    // if (canInit) {
    //   this.savFilters = this.initFilters(savFilters, event);
    // } else {
    //   this.savFilters = savFilters;
    // }

    if (customers) {
      this.loadCustomers(searchClaimRequest, customers);
    }

    if (searchClaimRequest) {
      return this.claimService.claimSearchClaimsAsync(searchClaimRequest);
    }
  }

  private loadCustomers(sf: SearchClaimRequest, customers: Customer[]) {
    sf.customers = undefined;
    if (customers) {
      customers.forEach(
        (c, i) => {
              const client = customers[i];

              if (sf.customers === undefined) {
                  sf.customers = client.id.toString();
              } else {
                  sf.customers += ',' + client.id;
              }
        });
    }
  }

  public initFilters(sf: SavFilters, event?: any): SavFilters {
    sf.customers = undefined;
    sf.closed = sf.closed ? sf.closed : undefined;
    sf.id = sf.id ? sf.id : undefined;
    sf.name = sf.name ? sf.name : undefined;
    sf.period = sf.period ? sf.period : undefined;
    sf.visited = sf.visited ? sf.visited : undefined;
    sf.pageNumber = sf.pageNumber = event.page + 1;
    sf.pageSize =  event.rows | this.pageSize;

    return sf;
  }
  // public excelExport(searchLaunchedOnce: boolean, customers: Customer[], totalRecords: number ): Observable<any> {
  //   if (!searchLaunchedOnce) {
  //     return;
  //   }
  //   // loading = true;
  //   this.savFilters = this.initFilters(this.savFilters, {page: 0});
  //   this.savFilters.pageSize = totalRecords;
  //    return this.searchPage(null, this.savFilters, null, null, null, false);
  // }

  public initHeader(): any[] {

    const headers = [
      [
        this.translateService.instant('COMPLAINT_ID'),
        this.translateService.instant('COMPLAINT_WARRANTY'),
        this.translateService.instant('COMPLAINT_COMPLAINANT'),
        this.translateService.instant('COMPLAINT_COMMENT'),
        this.translateService.instant('COMPLAINT_PHONE'),
        this.translateService.instant('COMPLAINT_DATE'),
        this.translateService.instant('COMPLAINT_VISIT'),
        this.translateService.instant('COMPLAINT_CLOSURE_DATE')
      ]
    ];

    return headers;
  }
}
