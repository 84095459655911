import { environment } from '@env/environment';
import { Injectable, NgModule } from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { LocalStorage } from 'ngx-webstorage';
import { AuthToken, Session } from '@app/app.session';

const API_URL_TOKEN = '/api/';

@Injectable()
export class APIInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (environment.apiUrl && environment.apiUrl !== API_URL_TOKEN && req.url.startsWith(API_URL_TOKEN)) {
      const url = environment.apiUrl + req.url.substr(API_URL_TOKEN.length);
      req = req.clone({
        url: url
      });
    }
    return next.handle(req);
  }
}

@Injectable()
export class EdgeCorsFixInterceptor implements HttpInterceptor {
  // 'Access-Control-Allow-Origin'
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      headers: req.headers.set('Access-Control-Allow-Origin', '*')
    });
    return next.handle(req);
  }
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public session: Session) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.session.getToken()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.session.getToken().access_token}`
        }
      });
    }

    return next.handle(request);
  }
}

@NgModule({
  imports: [HttpClientModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: APIInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: EdgeCorsFixInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  ]
})
export class InterceptorsModule { }
