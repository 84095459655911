/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { AddPictureToDeliveryRack } from '@app/models/addPictureToDeliveryRack';
import { DeliveryRack } from '@app/models/deliveryRack';
import { DropOffRack } from '@app/models/dropOffRack';
import { FailToDropOffRack } from '@app/models/failToDropOffRack';
import { ReleaseDeliveryRack } from '@app/models/releaseDeliveryRack';
import { UpdateDeliveryRackInfo } from '@app/models/updateDeliveryRackInfo';
import { UpdateDeliveryRackLocation } from '@app/models/updateDeliveryRackLocation';

import { BASE_PATH } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';

@Injectable({ providedIn: 'root' })
export class DeliveryRackService {

  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration, public appConfig: AppConfig) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }



  /**
   * Retrieves the list of all delivery racks.
   *
   * @param page The page index.
   * @param customerId The client ID.
   * @param searchKey The delivery rack search key.
   * @param barCode The delivery rack barcode.
   * @param pageSize The page size (20 by default).
   * @param count count the number of rows.
   * @param customers List of customers (coma separated)
   * @param notreleased Show released delivery racks
   * @param sort
   * @param sortBy
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackGetAll(
    page: number, customerId?: number, searchKey?: string, barCode?: string, rackId?: number, address?: string, worksite?: string, pageSize?: number, count?: boolean,
    customers?: string, notreleased?: boolean, sort?: string, sortBy?: string, observe?: 'body', reportProgress?: boolean):
    Observable<any>;
  public deliveryRackGetAll(
    page: number, customerId?: number, searchKey?: string, barCode?: string, rackId?: number, address?: string, worksite?: string, pageSize?: number, count?: boolean,
    customers?: string, notreleased?: boolean, sort?: string, sortBy?: string, observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<any>>;
  public deliveryRackGetAll(
    page: number, customerId?: number, searchKey?: string, barCode?: string, rackId?: number, address?: string, worksite?: string, pageSize?: number, count?: boolean,
    customers?: string, notreleased?: boolean, sort?: string, sortBy?: string, observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<any>>;
  public deliveryRackGetAll(
    page: number, customerId?: number, searchKey?: string, barCode?: string, rackId?: number, address?: string, worksite?: string, pageSize?: number, count?: boolean,
    customers?: string, notreleased?: boolean, sort?: string, sortBy?: string, observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {
    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (customerId !== undefined) {
      queryParameters = queryParameters.set('customerId', <any>customerId);
    }
    if (searchKey !== undefined) {
      queryParameters = queryParameters.set('searchKey', <any>searchKey);
    }
    if (barCode !== undefined) {
      queryParameters = queryParameters.set('barCode', <any>barCode);
    }
    if (rackId !== undefined) {
      queryParameters = queryParameters.set('rackId', <any>rackId);
    }
    if (address !== undefined) {
      queryParameters = queryParameters.set('address', <any>address);
    }
    if (worksite !== undefined) {
      queryParameters = queryParameters.set('worksite', <any>worksite);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (customers !== undefined) {
      queryParameters = queryParameters.set('customers', <any>customers);
    }
    if (notreleased !== undefined) {
      queryParameters = queryParameters.set('notreleased', <any>notreleased);
    }
    if (sort !== undefined) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (sortBy !== undefined) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<any>(`${this.basePath}/api/v1/deliveryRacks/${encodeURIComponent(String(page))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single delivery rack.
   *
   * @param id The delivery rack ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackGetById(id: number, observe?: 'body', reportProgress?: boolean): Observable<DeliveryRack>;
  public deliveryRackGetById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DeliveryRack>>;
  public deliveryRackGetById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DeliveryRack>>;
  public deliveryRackGetById(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deliveryRackGetById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<DeliveryRack>(`${this.basePath}/api/v1/deliveryRack/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id The rack ID
   * @param parameters The operation parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackPostDropOff(
    id: number, parameters: DropOffRack, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deliveryRackPostDropOff(
    id: number, parameters: DropOffRack, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deliveryRackPostDropOff(
    id: number, parameters: DropOffRack, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deliveryRackPostDropOff(
    id: number, parameters: DropOffRack, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deliveryRackPostDropOff.');
    }
    if (parameters === null || parameters === undefined) {
      throw new Error('Required parameter parameters was null or undefined when calling deliveryRackPostDropOff.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/deliveryRack/${encodeURIComponent(String(id))}/DropOff`,
      parameters,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id The rack ID
   * @param parameters The operation parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackPostDropOffFailure(
    id: number, parameters: FailToDropOffRack, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deliveryRackPostDropOffFailure(
    id: number, parameters: FailToDropOffRack, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deliveryRackPostDropOffFailure(
    id: number, parameters: FailToDropOffRack, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deliveryRackPostDropOffFailure(
    id: number, parameters: FailToDropOffRack, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deliveryRackPostDropOffFailure.');
    }
    if (parameters === null || parameters === undefined) {
      throw new Error('Required parameter parameters was null or undefined when calling deliveryRackPostDropOffFailure.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/deliveryRack/${encodeURIComponent(String(id))}/DropOffFailure`,
      parameters,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Adds a photo to a delivery rack.
   *
   * @param id The delivery rack ID.
   * @param parameters The operation parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackPostPhoto(
    id: number, parameters: AddPictureToDeliveryRack, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deliveryRackPostPhoto(
    id: number, parameters: AddPictureToDeliveryRack, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deliveryRackPostPhoto(
    id: number, parameters: AddPictureToDeliveryRack, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deliveryRackPostPhoto(
    id: number, parameters: AddPictureToDeliveryRack, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deliveryRackPostPhoto.');
    }
    if (parameters === null || parameters === undefined) {
      throw new Error('Required parameter parameters was null or undefined when calling deliveryRackPostPhoto.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/deliveryRack/${encodeURIComponent(String(id))}/photo`,
      parameters,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Updates an existing delivery rack (information).
   *
   * @param id The rack ID.
   * @param information The new information.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackPut(
    id: number, information: UpdateDeliveryRackInfo, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deliveryRackPut(
    id: number, information: UpdateDeliveryRackInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deliveryRackPut(
    id: number, information: UpdateDeliveryRackInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deliveryRackPut(
    id: number, information: UpdateDeliveryRackInfo, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deliveryRackPut.');
    }
    if (information === null || information === undefined) {
      throw new Error('Required parameter information was null or undefined when calling deliveryRackPut.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/api/v1/deliveryRack/${encodeURIComponent(String(id))}`,
      information,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Updates an existing delivery rack (location).
   *
   * @param id The rack ID.
   * @param parameters The new information.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackPut_1(
    id: number, parameters: UpdateDeliveryRackLocation, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deliveryRackPut_1(
    id: number, parameters: UpdateDeliveryRackLocation, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deliveryRackPut_1(
    id: number, parameters: UpdateDeliveryRackLocation, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deliveryRackPut_1(
    id: number, parameters: UpdateDeliveryRackLocation, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling deliveryRackPut_1.');
    }
    if (parameters === null || parameters === undefined) {
      throw new Error('Required parameter parameters was null or undefined when calling deliveryRackPut_1.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/api/v1/deliveryRack/${encodeURIComponent(String(id))}/location`,
      parameters,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Release an existing delivery rack.
   *
   * @param parameters
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliveryRackRelease(
    parameters: ReleaseDeliveryRack, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public deliveryRackRelease(
    parameters: ReleaseDeliveryRack, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public deliveryRackRelease(
    parameters: ReleaseDeliveryRack, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public deliveryRackRelease(
    parameters: ReleaseDeliveryRack, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (parameters === null || parameters === undefined) {
      throw new Error('Required parameter parameters was null or undefined when calling deliveryRackRelease.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/deliveryRack/release`,
      parameters,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
