/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { OrderDetail } from '@app/models/orderDetail';

import { BASE_PATH } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';
import { EntitiesWithTotalOrder } from '@app/models/models';
import { EntitiesWithTotalOrderDetail } from '@app/models/entitiesWithTotalOrderDetail';

@Injectable({ providedIn: 'root' })
export class OrderDetailService {

  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration,
    public appConfig: AppConfig) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }



  /**
   * Retrieves the orders.
   *
   * @param page The page index.
   * @param clientId The client ID.
   * @param pageSize The page size (20 by default).
   * @param count count the number of rows.
   * @param period The delivery period.
   * @param references Order id, order ref, ...
   * @param customers Array of customers.
   * @param year Order&#39;s Year.
   * @param rack Order&#39;s Rack.
   * @param fromDate From.
   * @param toDate To.
   * @param sort Column to sort.
   * @param sortBy Sort by.
   * @param width OrderLine width.
   * @param height OrderLine height.
   * @param latelydelivered Lately delivered orders.
   * @param notdelivered Not delivered orders.
   * @param missing Order is missing something
   * @param barcode Order&#39;s barcode.
   * @param orderID Order&#39;s ID.
   * @param glassID Order&#39;s Glass ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderDetailGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, references?: string, customers?: string,
    year?: number, rack?: string, fromDate?: string, toDate?: string, sort?: string, sortBy?: string, width?: number, height?: number,
    latelydelivered?: boolean, notdelivered?: boolean, missing?: boolean, barcode?: string, orderID?: string, glassID?: string,
    observe?: 'body', reportProgress?: boolean): Observable<EntitiesWithTotalOrderDetail>;
  public orderDetailGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, references?: string, customers?: string,
    year?: number, rack?: string, fromDate?: string, toDate?: string, sort?: string, sortBy?: string, width?: number, height?: number,
    latelydelivered?: boolean, notdelivered?: boolean, missing?: boolean, barcode?: string, orderID?: string, glassID?: string,
    observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<EntitiesWithTotalOrderDetail>>;
  public orderDetailGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, references?: string, customers?: string,
    year?: number, rack?: string, fromDate?: string, toDate?: string, sort?: string, sortBy?: string, width?: number, height?: number,
    latelydelivered?: boolean, notdelivered?: boolean, missing?: boolean, barcode?: string, orderID?: string, glassID?: string,
    observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<EntitiesWithTotalOrderDetail>>;
  public orderDetailGetAll(
    page: number, clientId?: number, pageSize?: number, count?: boolean, period?: number, references?: string, customers?: string,
    year?: number, rack?: string, fromDate?: string, toDate?: string, sort?: string, sortBy?: string, width?: number, height?: number,
    latelydelivered?: boolean, notdelivered?: boolean, missing?: boolean, barcode?: string, orderID?: string, glassID?: string,
    observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (clientId !== undefined) {
      queryParameters = queryParameters.set('clientId', <any>clientId);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (period !== undefined) {
      queryParameters = queryParameters.set('period', <any>period);
    }
    if (references !== undefined) {
      queryParameters = queryParameters.set('references', <any>references);
    }
    if (customers !== undefined) {
      queryParameters = queryParameters.set('customers', <any>customers);
    }
    if (year !== undefined) {
      queryParameters = queryParameters.set('year', <any>year);
    }
    if (rack !== undefined) {
      queryParameters = queryParameters.set('rack', <any>rack);
    }
    if (fromDate !== undefined) {
      queryParameters = queryParameters.set('fromDate', <any>fromDate);
    }
    if (toDate !== undefined) {
      queryParameters = queryParameters.set('toDate', <any>toDate);
    }
    if (sort !== undefined) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (sortBy !== undefined) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (width !== undefined) {
      queryParameters = queryParameters.set('width', <any>width);
    }
    if (height !== undefined) {
      queryParameters = queryParameters.set('height', <any>height);
    }
    if (latelydelivered !== undefined) {
      queryParameters = queryParameters.set('latelydelivered', <any>latelydelivered);
    }
    if (notdelivered !== undefined) {
      queryParameters = queryParameters.set('notdelivered', <any>notdelivered);
    }
    if (missing !== undefined) {
      queryParameters = queryParameters.set('missing', <any>missing);
    }
    if (barcode !== undefined) {
      queryParameters = queryParameters.set('barcode', <any>barcode);
    }
    if (orderID !== undefined) {
      queryParameters = queryParameters.set('orderID', <any>orderID);
    }
    if (glassID !== undefined) {
      queryParameters = queryParameters.set('glassID', <any>glassID);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<Array<OrderDetail>>(`${this.basePath}/api/v1/detailedOrders/${encodeURIComponent(String(page))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single order.
   *
   * @param id The order ID
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderDetailGetById(id: number, observe?: 'body', reportProgress?: boolean): Observable<OrderDetail>;
  public orderDetailGetById(id: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDetail>>;
  public orderDetailGetById(id: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDetail>>;
  public orderDetailGetById(id: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling orderDetailGetById.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<OrderDetail>(`${this.basePath}/api/v1/detailedOrder/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single order.
   *
   * @param year The order Year
   * @param cmd The order CMD
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public orderDetailGetByOrderId(
    year: number, cmd: number, observe?: 'body', reportProgress?: boolean): Observable<OrderDetail>;
  public orderDetailGetByOrderId(
    year: number, cmd: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderDetail>>;
  public orderDetailGetByOrderId(
    year: number, cmd: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderDetail>>;
  public orderDetailGetByOrderId(
    year: number, cmd: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (year === null || year === undefined) {
      throw new Error('Required parameter year was null or undefined when calling orderDetailGetByOrderId.');
    }
    if (cmd === null || cmd === undefined) {
      throw new Error('Required parameter cmd was null or undefined when calling orderDetailGetByOrderId.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (year !== undefined) {
      queryParameters = queryParameters.set('year', <any>year);
    }
    if (cmd !== undefined) {
      queryParameters = queryParameters.set('cmd', <any>cmd);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<OrderDetail>(`${this.basePath}/api/v1/detailedOrder/cmd`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
