/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { ClaimHeader } from '@app/models/claimHeader';
import { SearchClaimRequest } from '@app/models/searchClaimRequest';
import { CreateCartHeaderResult } from '@app/models/createCartHeaderResult';
import { NewClaim } from '@app/models/newClaim';
import { NewClaimLine } from '@app/models/newClaimLine';
import { ClaimPicture } from '@app/models/claimPicture';

import { BASE_PATH, COLLECTION_FORMATS } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';
import { GetClaimPictureRequest } from '@app/models/getClaimPictureRequest';

@Injectable({providedIn: 'root'})
export class ClaimService {

    protected basePath: string = this.appConfig.configServer.boServerPath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    public newSav: NewClaim;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration,
      public appConfig: AppConfig) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }

        this.newSav = {} as NewClaim;
        this.newSav.claimLines = [] as NewClaimLine[];
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * Creates the claim asynchronously.
     *
     * @param claim The new claim to create.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public claimCreateClaimAsync(claim: NewClaim, observe?: 'body', reportProgress?: boolean): Observable<CreateCartHeaderResult>;
    public claimCreateClaimAsync(claim: NewClaim, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CreateCartHeaderResult>>;
    public claimCreateClaimAsync(claim: NewClaim, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CreateCartHeaderResult>>;
    public claimCreateClaimAsync(claim: NewClaim, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (claim === null || claim === undefined) {
            throw new Error('Required parameter claim was null or undefined when calling claimCreateClaimAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<CreateCartHeaderResult>(`${this.basePath}/api/v1/claim`,
            claim,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Searches the claims asynchronously.
     *
     * @param request The request.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public claimSearchClaimsAsync(request: SearchClaimRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<ClaimHeader>>;
    public claimSearchClaimsAsync(request: SearchClaimRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClaimHeader>>>;
    public claimSearchClaimsAsync(request: SearchClaimRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClaimHeader>>>;
    public claimSearchClaimsAsync(request: SearchClaimRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling claimSearchClaimsAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ClaimHeader>>(`${this.basePath}/api/v1/claims`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param request
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public claimGetClaimPicturesAsync(request: GetClaimPictureRequest, observe?: 'body', reportProgress?: boolean): Observable<Array<ClaimPicture>>;
    public claimGetClaimPicturesAsync(request: GetClaimPictureRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClaimPicture>>>;
    public claimGetClaimPicturesAsync(request: GetClaimPictureRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClaimPicture>>>;
    public claimGetClaimPicturesAsync(request: GetClaimPictureRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling claimGetClaimPicturesAsync.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<Array<ClaimPicture>>(`${this.basePath}/api/v1/claim/pictures`,
            request,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
