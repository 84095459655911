import { DeliveryRackService } from './../../../services/deliveryRack.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ReleaseDeliveryRack } from '@app/models/models';

@Component({
  selector: 'app-delivery-racks-release-dialog',
  templateUrl: './delivery-racks-release-dialog.component.html',
  styleUrls: ['./delivery-racks-release-dialog.component.scss']
})
export class DeliveryRacksReleaseDialogComponent implements OnInit {

  @Output() rackReleased: EventEmitter<any> = new EventEmitter();
  dialogVisible = false;
  parameters: ReleaseDeliveryRack;

  constructor(private deliveryRackservice: DeliveryRackService) { }

  ngOnInit() {
    this.parameters = {} as ReleaseDeliveryRack;
  }

  show(deliveryRackId: number) {
    this.parameters.deliveryRackID = deliveryRackId;
    this.parameters.toThrow = false;
    this.parameters.empty = true;
    this.dialogVisible = true;
  }

  releaseRack() {
    this.deliveryRackservice.deliveryRackRelease(this.parameters).subscribe(() => {
      this.rackReleased.emit();
      this.dialogVisible = false;

    }, (err) => {
      this.dialogVisible = false;
      console.log(err);
    });
  }
}
