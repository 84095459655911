/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { BASE_PATH, COLLECTION_FORMATS } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';
import { TokenServer } from '@app/models/tokenServer';

@Injectable({ providedIn: 'root' })
export class TokenService {
  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    public appConfig: AppConfig
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param grantType
   * @param username
   * @param password
   * @param scope
   * @param deviceId
   * @param appId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tokenPost(
    grantType: string,
    username?: string,
    password?: string,
    scope?: string,
    deviceId?: string,
    appId?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TokenServer>;
  public tokenPost(
    grantType: string,
    username?: string,
    password?: string,
    scope?: string,
    deviceId?: string,
    appId?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TokenServer>>;
  public tokenPost(
    grantType: string,
    username?: string,
    password?: string,
    scope?: string,
    deviceId?: string,
    appId?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TokenServer>>;
  public tokenPost(
    grantType: string,
    username?: string,
    password?: string,
    scope?: string,
    deviceId?: string,
    appId?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (grantType === null || grantType === undefined) {
      throw new Error(
        'Required parameter grantType was null or undefined when calling tokenPost.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/x-www-form-urlencoded'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any }; // TODO avant void
    const useForm = false;
    const convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (grantType !== undefined) {
      formParams =
        formParams.append('grant_type', <any>grantType) || formParams; // TODO pourquoi || formParams ?
    }
    if (username !== undefined) {
      formParams = formParams.append('username', <any>username) || formParams;
    }
    if (password !== undefined) {
      formParams = formParams.append('password', <any>password) || formParams;
    }
    if (scope !== undefined) {
      formParams = formParams.append('scope', <any>scope) || formParams;
    }
    if (deviceId !== undefined) {
      formParams = formParams.append('device_id', <any>deviceId) || formParams;
    }
    if (appId !== undefined) {
      formParams = formParams.append('app_id', <any>appId) || formParams;
    }

    return this.httpClient.post<any>(
      `${this.basePath}/Token`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param grantType
   * @param refreshToken
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tokenRefreshPost(
    grantType: string,
    refreshToken: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<TokenServer>;
  public tokenRefreshPost(
    grantType: string,
    refreshToken: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<TokenServer>>;
  public tokenRefreshPost(
    grantType: string,
    refreshToken: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<TokenServer>>;
  public tokenRefreshPost(
    grantType: string,
    refreshToken: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (grantType === null || grantType === undefined) {
      throw new Error(
        'Required parameter grantType was null or undefined when calling tokenRefreshPost.'
      );
    }

    if (refreshToken === null || refreshToken === undefined) {
      throw new Error(
        'Required parameter refreshToken was null or undefined when calling tokenRefreshPost.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/x-www-form-urlencoded'];

    const canConsumeForm = this.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any }; // TODO avant void
    const useForm = false;
    const convertFormParamsToString = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new HttpParams({
        encoder: new CustomHttpUrlEncodingCodec()
      });
    }

    if (grantType !== undefined) {
      formParams =
        formParams.append('grant_type', <any>grantType) || formParams; // TODO pourquoi || formParams ?
    }
    if (refreshToken !== undefined) {
      formParams = formParams.append('refresh_token', <any>refreshToken) || formParams;
    }

    return this.httpClient.post<any>(
      `${this.basePath}/Token`,
      convertFormParamsToString ? formParams.toString() : formParams,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tokenTryToken(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public tokenTryToken(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public tokenTryToken(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public tokenTryToken(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(`${this.basePath}/api/v1/tryToken`, {
      withCredentials: this.configuration.withCredentials,
      headers: headers,
      observe: observe,
      reportProgress: reportProgress
    });
  }
}
