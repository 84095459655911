import { Component, OnInit, ViewChild } from '@angular/core';
import { OrderDetail } from '@app/models/models';
import { OrdersDetailsComponent } from '../orders-details/orders-details.component';

@Component({
  selector: 'app-orders-details-dialog',
  templateUrl: './orders-details-dialog.component.html',
  styleUrls: ['./orders-details-dialog.component.scss']
})
export class OrdersDetailsDialogComponent implements OnInit {

  orderDetail: OrderDetail;
  total: string;
  dialogVisible = false;

  @ViewChild('ordersDetailsComponent') ordersDetailsComponent: OrdersDetailsComponent;

  constructor() { }

  ngOnInit() {
    this.orderDetail = {};
  }

  public show(orderDetail: OrderDetail) {
    this.orderDetail = orderDetail;
    if (this.orderDetail.partialRemoval) {
      this.total = 'partial';
    } else {
      this.total = 'total';
    }
    this.ordersDetailsComponent.setMaps(this.orderDetail);
    this.dialogVisible = true;
  }

  print() {
    window.open('#/printing/printing-order-details?orderId=' + this.orderDetail.orderSequence +
    '&year=' + this.orderDetail.orderYear, '_blank');
  }
}
