import {Component, Input} from '@angular/core';
import {OverlayPanel} from 'primeng/primeng';

@Component({
  selector: 'app-read-more',
  template: `
    <span *ngIf="isOverlay && isText" (mouseenter)="showMore($event)">{{limitText}} <span>[...]</span></span>
    <span *ngIf="!isOverlay && isText" pTooltip="{{fullText}}" tooltipPosition="bottom">{{limitText}} <span>[...]</span></span>
    <span *ngIf="!isText">{{fullText}}</span>
  `
})
export class ReadMoreComponent {
  @Input() text: string;
  @Input() max = 20;
  @Input() overlay: OverlayPanel;

  get isOverlay() {
    return (this.overlay !== undefined ? true : false);
  }

  get isText(): boolean {
    return (this.text ? this.text.length > this.max : false);
  }

  get limitText(): string {
    return (this.text !== undefined ? this.text.slice(0, this.max) : null);
  }

  get fullText(): string {
    return (this.text !== undefined ? this.text : null);
  }

  showMore(event) {
    const div = this.overlay.el.nativeElement.querySelector('.ui-overlaypanel-content');
    const textDiv = div.querySelector('.readmore-content');
    if (textDiv) {
      textDiv.remove();
    }
    div.insertAdjacentHTML('beforeEnd',
      '<div class="readmore-content" ' +
      'style="max-width:500px;max-height:250px;padding: 5px 15px 5px 10px;overflow-y:auto;text-align:justify;">' +
      '${this.fullText}' +
      '</div>');
    this.overlay.toggle(event);
  }
}
