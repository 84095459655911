import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-check-viewer',
    template: `
      <i *ngIf="!valuee" class="material-icons" style="margin-left: -3px">check_box_outline_blank</i>
      <i *ngIf="valuee" class="material-icons" style="margin-left: -3px">check_box</i>
    `
})
export class CheckViewerComponent {
  @Input() value: boolean;

  get valuee() {
    return (this.value !== undefined ? this.value : false);
  }
}
