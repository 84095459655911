import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor() { }

  exportToXlxs(headers: string[][], data: any, sheet: string, filename: string) {
    if (!filename.includes('.xlsx')) {
      filename += '.xlsx';
    }
    const ws = XLSX.utils.aoa_to_sheet(headers);
    XLSX.utils.sheet_add_json(ws, data, {
      header: [],
      skipHeader: true,
      origin: 1,
    });

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, sheet);
    const wbout = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
    saveAs(new Blob([wbout], {type: 'application/octet-stream'}), filename);
  }

}
