import { Router } from '@angular/router';
import { ComplaintDetail } from './../../../../models/complaintDetail';
import { Component, OnInit, Input } from '@angular/core';
import { ClaimHeader } from '@app/models/claimHeader';
import { GetClaimPictureRequest } from '@app/models/getClaimPictureRequest';
import { ClaimLineService } from '@app/services/claimLine.service';
import { ClaimPicture } from '@app/models/claimPicture';

@Component({
  selector: 'app-sav-details',
  templateUrl: './sav-details.component.html',
  styleUrls: ['./sav-details.component.scss']
})
export class SavDetailsComponent implements OnInit {

  @Input() public complaintDetail: ComplaintDetail;
  @Input() public claimDetail: ClaimHeader;
  @Input() public pictures: ClaimPicture[] = [];

  constructor(private router: Router,
              private claimLineService: ClaimLineService) { }

  ngOnInit() {
    this.complaintDetail = {};
    this.claimDetail = {};
  }

  public setComplaintDetails(cd: ComplaintDetail) {
    this.complaintDetail = cd;
  }

  public setClaimDetails(cd: ClaimHeader) {
    this.claimDetail = cd;
  }

  public goOrderDetails(year: number, id: number, line: number) {
    this.router.navigate(['/orders', { queryParams: { year: year } } ]);
  }

  public openPicture(p: any) {

        // tslint:disable-next-line:max-line-length
        const iframe = '<iframe src="data:image/png;base64,' + p + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>';
        const x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
  }

}
