import { UserBoFilters } from '@app/models/filters/userBoFilters';
import { LazyLoadEvent } from 'primeng/primeng';
import { ConfirmationService } from 'primeng/api';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { UserBO } from '@app/models/userBO';
import { Component, OnInit, Input } from '@angular/core';
import { ExportService } from '@app/services/export.service';
import { UserBOService } from '@app/services/userBO.service';
import { Session } from '@app/app.session';

@Component({
  selector: 'app-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss']
})
export class UsersTableComponent implements OnInit {

  @Input() usersBO: UserBO[];
  @Input() loading = false;
  @Input() printingMode = false;
  @Input() filters: UserBoFilters;

  public pageSize = 10;

  totalRecords: number;

  exportExcelHeaders =
    [
      [
        this.translateService.instant('USERS_ID'),
        this.translateService.instant('USERS_NAME'),
        this.translateService.instant('VIEWABLE_CUSTOMERS'),
        'Langue',
        'Email',
      ]
    ];

  columns: any[];

  constructor(private translateService: TranslateService,
    private usersService: UserBOService,
    private session: Session,
    private confirmationService: ConfirmationService,
    private exportService: ExportService) {
    this.translateService.onLangChange.subscribe(this.onLangChange.bind(this));

    if (this.session && this.session.connectedUser && this.session.connectedUser.languageCode) {
      this.translateService.use(this.session.connectedUser.languageCode);
    }
  }

  onLangChange(langE: LangChangeEvent) {
    this.translate();
  }

  translate() {
    this.columns =
      [
        { field: 'username', header: this.translateService.instant('USERS_ID') },
        { field: 'name', header: this.translateService.instant('USERS_NAME') },
        { field: 'viewableCustomers', header: this.translateService.instant('VIEWABLE_CUSTOMERS') },
        // Not translated in other app
        { field: 'languageCode', header: 'Langue' },
        { field: 'email', header: 'Email' }
      ];
  }

  ngOnInit() {
    this.translate();
  }

  exportExcel() {
    if (this.totalRecords > 0) {
      this.usersService.userBOGetAll(
        undefined,
        this.filters.clientId,
        this.totalRecords,
        this.filters.count,
        this.filters.sort,
        this.filters.sortBy,
        this.filters.id,
        this.filters.name).subscribe(r => {
          const users = <UserBO[]>r.entities;
          const usersToExport: any[] = [];
          users.forEach(user => {

            const customers = '';
            // To export list of viewable customers
            // Not done in other app : column always empty in xml
            // user.permissions.forEach((p, i) => {
            //   customers += ' ' + p.customer.name;
            //   if (!user.permissions.length) { customers += ' - '; }
            // });
            const u = {
              id: user.id,
              name: user.name,
              permissions: customers,
              languageCode: user.languageCode,
              email: user.email
            };
            usersToExport.push(u);
          });
          this.exportService.exportToXlxs(this.exportExcelHeaders, usersToExport, 'Sheet1', 'users');
          this.loading = false;
        }, (err) => {
          this.loading = false;
          console.log(err);
        });
    }
  }

  print() {
    if (this.totalRecords > 0) {
      this.filters.pageSize = this.totalRecords;
      let url = '';
      Object.keys(this.filters).forEach(prop => {
        if (this.filters[prop] !== null && this.filters[prop] !== undefined) {
          url += '&' + prop + '=' + this.filters[prop];
        }
      });
      window.open('#/printing/printing-users?' + url, '_blank');
    }
  }

  delete(user: UserBO) {
    this.confirmationService.confirm({
      message: this.translateService.instant('DELETING_USER_CONFIRM'),
      header: this.translateService.instant('DELETING'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.usersService.userBODeleteUser(user.id).subscribe(() => {
          this.searchPage({ page: this.filters.page });
        }, (err) => {
          console.log(err);
        });
      },
      reject: () => { }
    });
  }

  searchPage(event) {
    this.loading = true;

    if (event && event.rows) {
      this.pageSize = event.rows;
    }

    this.filters.pageSize = this.pageSize;

    this.usersService.userBOGetAll(
      event.page + 1,
      this.filters.clientId,
      this.filters.pageSize,
      this.filters.count,
      this.filters.sort,
      this.filters.sortBy,
      this.filters.id,
      this.filters.name).subscribe(r => {
        this.usersBO = r.entities;
        this.totalRecords = r.total;
        this.loading = false;
      }, (err) => {
        this.loading = false;
        console.log(err);
      });
  }

  customSort(event: LazyLoadEvent) {
    if (!event.sortField || !this.filters) { return; }
    if (event.sortOrder === 1) {
      this.filters.sortBy = 'asc';
    } else {
      this.filters.sortBy = 'desc';
    }
    this.filters.sort = event.sortField;
    this.searchPage({ page: 0 });
  }
}
