import {Injectable} from '@angular/core';
import {Session} from '@app/app.session';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {LocaleSettings} from 'primeng/primeng';


@Injectable({providedIn: 'root'})
export class CalendarTranslatorService {

  private settingsLoaded = false;
  private calendarSettings: CalendarSettings;

  constructor(
    private http: HttpClient,
    private session: Session
  ) {
  }

  loadAndStoreSettings() {
    this.getJSON().subscribe(value => {
      this.calendarSettings = value;
      this.settingsLoaded = true;
    });
  }

  getJSON(): Observable<any> {
    return this.http.get(`assets/i18n/calendar-${this.session.connectedUser.languageCode}.json`);
  }

  instant(): CalendarSettings {
    return this.calendarSettings;
  }

}

export class CalendarSettings {
  localeSettings: LocaleSettings;
  dateFormat: string;
}
