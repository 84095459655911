import {Layout} from '@app/models/layout.model';
import {ConfigServer} from '@app/models/configServer';
import {Folder} from '@app/models/folder';
import {Images} from '@app/models/images';

export class AppConfig {
  // Global
  lang = 'F';
  languages: string[] = [];

  // Layout
  layout: Layout = new Layout();

  // config server
  configServer: ConfigServer = new ConfigServer();

  // folder
  folder: Folder = new Folder();

  // images
  images: Images = new Images();
}
