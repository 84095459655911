/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { DropOffOrderLine } from '@app/models/dropOffOrderLine';
import { FailToDropOffOrderLine } from '@app/models/failToDropOffOrderLine';
import { OrderLine } from '@app/models/orderLine';

import { BASE_PATH, COLLECTION_FORMATS } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import {AppConfig} from '@app/models/app-config.model';

@Injectable({providedIn: 'root'})
export class OrderLineService {

    protected basePath: string = this.appConfig.configServer.boServerPath;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
      protected httpClient: HttpClient,
      @Optional() @Inject(BASE_PATH) basePath: string,
      @Optional() configuration: Configuration,
      public appConfig: AppConfig) {

        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Retrieves the orders.
     *
     * @param clientId The client ID.
     * @param customers
     * @param year
     * @param numCmd
     * @param reference
     * @param barcode
     * @param width
     * @param height
     * @param rackId
     * @param status
     * @param missing
     * @param notdelivered
     * @param latelydelivered
     * @param period
     * @param fromDate
     * @param toDate
     * @param glassID
     * @param orderLineID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderLineGetAll(clientId?: number, customers?: string, year?: number, numCmd?: string, reference?: string,
                           barcode?: string, width?: string, height?: string, rackId?: string, status?: string,
                           missing?: string, notdelivered?: string, latelydelivered?: string, period?: number,
                           fromDate?: string, toDate?: string, glassID?: string, orderLineID?: string, observe?: 'body',
                           reportProgress?: boolean): Observable<Array<OrderLine>>;
    public orderLineGetAll(clientId?: number, customers?: string, year?: number, numCmd?: string, reference?: string,
                           barcode?: string, width?: string, height?: string, rackId?: string, status?: string,
                           missing?: string, notdelivered?: string, latelydelivered?: string, period?: number,
                           fromDate?: string, toDate?: string, glassID?: string, orderLineID?: string, observe?: 'response',
                           reportProgress?: boolean): Observable<HttpResponse<Array<OrderLine>>>;
    public orderLineGetAll(clientId?: number, customers?: string, year?: number, numCmd?: string, reference?: string,
                           barcode?: string, width?: string, height?: string, rackId?: string, status?: string,
                           missing?: string, notdelivered?: string, latelydelivered?: string, period?: number,
                           fromDate?: string, toDate?: string, glassID?: string, orderLineID?: string, observe?: 'events',
                           reportProgress?: boolean): Observable<HttpEvent<Array<OrderLine>>>;
    public orderLineGetAll(clientId?: number, customers?: string, year?: number, numCmd?: string, reference?: string,
                           barcode?: string, width?: string, height?: string, rackId?: string, status?: string,
                           missing?: string, notdelivered?: string, latelydelivered?: string, period?: number,
                           fromDate?: string, toDate?: string, glassID?: string, orderLineID?: string, observe: any = 'body',
                           reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (clientId !== undefined) {
            queryParameters = queryParameters.set('clientId', <any>clientId);
        }
        if (customers !== undefined) {
            queryParameters = queryParameters.set('customers', <any>customers);
        }
        if (year !== undefined) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (numCmd !== undefined) {
            queryParameters = queryParameters.set('numCmd', <any>numCmd);
        }
        if (reference !== undefined) {
            queryParameters = queryParameters.set('reference', <any>reference);
        }
        if (barcode !== undefined) {
            queryParameters = queryParameters.set('barcode', <any>barcode);
        }
        if (width !== undefined) {
            queryParameters = queryParameters.set('width', <any>width);
        }
        if (height !== undefined) {
            queryParameters = queryParameters.set('height', <any>height);
        }
        if (rackId !== undefined) {
            queryParameters = queryParameters.set('rackId', <any>rackId);
        }
        if (status !== undefined) {
            queryParameters = queryParameters.set('status', <any>status);
        }
        if (missing !== undefined) {
            queryParameters = queryParameters.set('missing', <any>missing);
        }
        if (notdelivered !== undefined) {
            queryParameters = queryParameters.set('notdelivered', <any>notdelivered);
        }
        if (latelydelivered !== undefined) {
            queryParameters = queryParameters.set('latelydelivered', <any>latelydelivered);
        }
        if (period !== undefined) {
            queryParameters = queryParameters.set('period', <any>period);
        }
        if (fromDate !== undefined) {
            queryParameters = queryParameters.set('fromDate', <any>fromDate);
        }
        if (toDate !== undefined) {
            queryParameters = queryParameters.set('toDate', <any>toDate);
        }
        if (glassID !== undefined) {
            queryParameters = queryParameters.set('glassID', <any>glassID);
        }
        if (orderLineID !== undefined) {
            queryParameters = queryParameters.set('orderLineID', <any>orderLineID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<OrderLine>>(`${this.basePath}/api/v1/orderLine`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param year
     * @param orderId
     * @param seqId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderLineGetDetail(year: number, orderId: number,
                              seqId: number, observe?: 'body', reportProgress?: boolean): Observable<OrderLine>;
    public orderLineGetDetail(year: number, orderId: number,
                              seqId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderLine>>;
    public orderLineGetDetail(year: number, orderId: number,
                              seqId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderLine>>;
    public orderLineGetDetail(year: number, orderId: number,
                              seqId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (year === null || year === undefined) {

            throw new Error('Required parameter year was null or undefined when calling orderLineGetDetail.');
        }
        if (orderId === null || orderId === undefined) {
            throw new Error('Required parameter orderId was null or undefined when calling orderLineGetDetail.');
        }
        if (seqId === null || seqId === undefined) {
            throw new Error('Required parameter seqId was null or undefined when calling orderLineGetDetail.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (year !== undefined) {
            queryParameters = queryParameters.set('year', <any>year);
        }
        if (orderId !== undefined) {
            queryParameters = queryParameters.set('orderId', <any>orderId);
        }
        if (seqId !== undefined) {
            queryParameters = queryParameters.set('seqId', <any>seqId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderLine>(`${this.basePath}/api/v1/orderLineDetail`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param glassId
     * @param customerID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderLineGetOrderFromGlassId(glassId: string, customerID: number,
                                        observe?: 'body', reportProgress?: boolean): Observable<OrderLine>;
    public orderLineGetOrderFromGlassId(glassId: string, customerID: number,
                                        observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OrderLine>>;
    public orderLineGetOrderFromGlassId(glassId: string, customerID: number,
                                        observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderLine>>;
    public orderLineGetOrderFromGlassId(glassId: string, customerID: number,
                                        observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (glassId === null || glassId === undefined) {
            throw new Error('Required parameter glassId was null or undefined when calling orderLineGetOrderFromGlassId.');
        }
        if (customerID === null || customerID === undefined) {
            throw new Error('Required parameter customerID was null or undefined when calling orderLineGetOrderFromGlassId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerID !== undefined) {
            queryParameters = queryParameters.set('customerID', <any>customerID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderLine>(
          `${this.basePath}/api/v1/orderLine/GetOrderFromGlassId/${encodeURIComponent(String(glassId))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param customerID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderLineGetOrderFromOrderId(id: string,
                                        customerID: number, observe?: 'body', reportProgress?: boolean): Observable<OrderLine>;
    public orderLineGetOrderFromOrderId(id: string,
                                        customerID: number, observe?: 'response', reportProgress?: boolean):
                                        Observable<HttpResponse<OrderLine>>;
    public orderLineGetOrderFromOrderId(id: string,
                                        customerID: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OrderLine>>;
    public orderLineGetOrderFromOrderId(id: string,
                                        customerID: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling orderLineGetOrderFromOrderId.');
        }
        if (customerID === null || customerID === undefined) {
            throw new Error('Required parameter customerID was null or undefined when calling orderLineGetOrderFromOrderId.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (customerID !== undefined) {
            queryParameters = queryParameters.set('customerID', <any>customerID);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<OrderLine>(`${this.basePath}/api/v1/orderLine/GetOrderFromOrderId/${encodeURIComponent(String(id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderLinePostDropOff(id: string, parameters: DropOffOrderLine,
                                observe?: 'body', reportProgress?: boolean): Observable<any>;
    public orderLinePostDropOff(id: string, parameters: DropOffOrderLine,
                                observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public orderLinePostDropOff(id: string, parameters: DropOffOrderLine,
                                observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public orderLinePostDropOff(id: string, parameters: DropOffOrderLine,
                                observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling orderLinePostDropOff.');
        }
        if (parameters === null || parameters === undefined) {
            throw new Error('Required parameter parameters was null or undefined when calling orderLinePostDropOff.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/v1/orderLine/${encodeURIComponent(String(id))}/DropOff`,
            parameters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param parameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public orderLinePostDropOffFailure(id: string, parameters: FailToDropOffOrderLine,
                                       observe?: 'body', reportProgress?: boolean): Observable<any>;
    public orderLinePostDropOffFailure(id: string, parameters: FailToDropOffOrderLine,
                                       observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public orderLinePostDropOffFailure(id: string, parameters: FailToDropOffOrderLine,
                                       observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public orderLinePostDropOffFailure(id: string, parameters: FailToDropOffOrderLine,
                                       observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling orderLinePostDropOffFailure.');
        }
        if (parameters === null || parameters === undefined) {
            throw new Error('Required parameter parameters was null or undefined when calling orderLinePostDropOffFailure.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'text/html',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.post<any>(`${this.basePath}/api/v1/orderLine/${encodeURIComponent(String(id))}/DropOffFailure`,
            parameters,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
