/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';

import { Observable } from 'rxjs/Observable';

import { MenuTileEntry } from '@app/models/menuTileEntry';

import { BASE_PATH } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';
import { AppConfig } from '@app/models/app-config.model';


@Injectable({ providedIn: 'root' })
export class TilesMenuService {

  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration,
    public appConfig: AppConfig) {
      if (basePath) {
        this.basePath = basePath;
      }
      if (configuration) {
        this.configuration = configuration;
        this.basePath = basePath || configuration.basePath || this.basePath;
      }
    }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }


  /**
   * Gets the entries.
   *
   * @param username The username.
   * @param clientId The client identifier.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public tilesMenuGetEntries(username: string,
                              clientId: number,
                              observe?: 'body',
                              reportProgress?: boolean): Observable<Array<MenuTileEntry>>;
  public tilesMenuGetEntries(username: string,
                              clientId: number,
                              observe?: 'response',
                              reportProgress?: boolean): Observable<HttpResponse<Array<MenuTileEntry>>>;
  public tilesMenuGetEntries(username: string,
                              clientId: number,
                              observe?: 'events',
                              reportProgress?: boolean): Observable<HttpEvent<Array<MenuTileEntry>>>;
  public tilesMenuGetEntries(username: string,
                              clientId: number,
                              observe: any = 'body',
                              reportProgress: boolean = false): Observable<any> {
    if (username === null || username === undefined) {
      throw new Error('Required parameter username was null or undefined when calling tilesMenuGetEntries.');
    }
    if (clientId === null || clientId === undefined) {
      throw new Error('Required parameter clientId was null or undefined when calling tilesMenuGetEntries.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.get<Array<MenuTileEntry>>(`${this.basePath}/api/v1/MenusTiles/${encodeURIComponent(String(username))}/${encodeURIComponent(String(clientId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
