import { Component, OnInit, Input } from '@angular/core';
import { NewClaimLine } from '@app/models/newClaimLine';

@Component({
  selector: 'app-sav-add-details',
  templateUrl: './sav-add-details.component.html',
  styleUrls: ['./sav-add-details.component.scss']
})
export class SavAddDetailsComponent implements OnInit {

  public newClaimLine: NewClaimLine;

  public setNewClaimLine(cl: NewClaimLine) {
    this.newClaimLine = cl;
  }
  constructor() { }

  ngOnInit() {
  }

}
