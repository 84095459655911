import { OrderLine } from '@app/models/orderLine';
import { Component, OnInit, Input } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-orders-line-details',
  templateUrl: './orders-line-details.component.html',
  styleUrls: ['./orders-line-details.component.scss']
})
export class OrdersLineDetailsComponent implements OnInit {

  @Input()
  public orderLineDetail: OrderLine;
  @Input()
  total: string;

  constructor(public datepipe: DatePipe) { }

  ngOnInit() { }

  public getDeliveryDate(orderLine: OrderLine): string {
    let result = '';
    if (orderLine && orderLine.deliveryDate) {
      orderLine.deliveryDate.forEach((d, i, a) => {
        this.datepipe.transform(d, 'dd/MM/yyyy') === '01/01/1900' ? result += '' : result += this.datepipe.transform(d, 'dd/MM/yyyy');
        if (i < a.length - 1) {
          result += ' ';
        }
      });
    }

    return result;
  }
}
