// export class Layout {
//   layoutCompact: true;
//   layoutMode: 'STATIC';
//   darkMenu: true;
//   profileMode: 'inline';
//   profileView: false;
//   demoMenu: true;
//   layoutMenu: true;
//   globalSearch: false;
//   footerView: false;
//   settingsView: false;
//   msgView: false;
//   notiView: false;
//   rightPanel: false;
//   breadcrumb: false;
//   avatar: 'avatar.png';
// }

export class Layout {
  layoutCompact: boolean;
  layoutMode: string;
  darkMenu: boolean;
  profileMode: string;
  profileView: boolean;
  demoMenu: boolean;
  layoutMenu: boolean;
  globalSearch: boolean;
  footerView: boolean;
  settingsView: boolean;
  msgView: boolean;
  notiView: boolean;
  rightPanel: boolean;
  breadcrumb: boolean;
  avatar: string;
}
