import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LayoutComponent} from '@app/features/layout/layout.component';
import {Subscription} from 'rxjs';
import {MenuItem} from 'primeng/primeng';
import {BreadcrumbService} from '@app/services/breadcrumb.service';
import {Session} from '@app/app.session';

@Component({
  selector: 'app-topbar-login',
  template: `
    <div class="topbar clearfix">
      <div class="topbar-left margin-auto">
        <div class="logo"></div>
      </div>
    </div>
  `
})
export class LayoutTopbarLoginComponent {

  subscription: Subscription;

  items: MenuItem[];

  logoutImage: any = '../assets/images/deconnexion.svg';
  profileImage: any = '../assets/images/profil.svg';
  infoImage: any = '../assets/images/info.png';

  constructor(public app: LayoutComponent,
              public i18n: TranslateService,
              public breadcrumbService: BreadcrumbService,
              public session: Session) {
    this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  get isMultiLang(): boolean {
    return (this.i18n.langs && this.i18n.langs.length > 1);
  }

  onLangChange(selectedLang: string) {
    this.i18n.use(selectedLang);
  }
}
