/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { Customer } from '@app/models/customer';

import { BASE_PATH, COLLECTION_FORMATS } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';
import {of} from 'rxjs';

import { AppConfig } from '@app/models/app-config.model';
import { CustomerCarts } from '@app/models/customerCarts';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  customers: Customer[];

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    public appConfig: AppConfig
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Retrieves the clients.
   *
   * @param page The page index.
   * @param pageSize The page size (20 by default).
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerGetAll(
    page: number,
    pageSize?: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Customer>>;
  public customerGetAll(
    page: number,
    pageSize?: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Customer>>>;
  public customerGetAll(
    page: number,
    pageSize?: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Customer>>>;
  public customerGetAll(
    page: number,
    pageSize?: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (page === null || page === undefined) {
      throw new Error(
        'Required parameter page was null or undefined when calling customerGetAll.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Customer>>(
      `${this.basePath}/api/v1/customers/${encodeURIComponent(String(page))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves the clients.
   *
   * @param user Username.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerGetAllViewable(
    user: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Customer>>;
  public customerGetAllViewable(
    user: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Customer>>>;
  public customerGetAllViewable(
    user: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Customer>>>;
  public customerGetAllViewable(
    user: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (user === null || user === undefined) {
      throw new Error(
        'Required parameter user was null or undefined when calling customerGetAllViewable.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Customer>>(
      `${this.basePath}/api/v1/customers/from/${encodeURIComponent(
        String(user)
      )}/viewable`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves the clients.
   *
   * @param userID USer ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerGetAllViewable_1(
    userID: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Customer>>;
  public customerGetAllViewable_1(
    userID: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Customer>>>;
  public customerGetAllViewable_1(
    userID: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Customer>>>;
  public customerGetAllViewable_1(
    userID: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (userID === null || userID === undefined) {
      throw new Error(
        'Required parameter userID was null or undefined when calling customerGetAllViewable_1.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Customer>>(
      `${this.basePath}/api/v1/customers/from/${encodeURIComponent(
        String(userID)
      )}/viewableById`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves the clients.
   *
   * @param customer Customer ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerGetAll_2(
    customer: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Customer>>;
  public customerGetAll_2(
    customer: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Customer>>>;
  public customerGetAll_2(
    customer: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Customer>>>;
  public customerGetAll_2(
    customer: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (customer === null || customer === undefined) {
      throw new Error(
        'Required parameter customer was null or undefined when calling customerGetAll_2.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<Customer>>(
      `${this.basePath}/api/v1/customers/from/${encodeURIComponent(
        String(customer)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single client.
   *
   * @param id The client ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerGetById(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Customer>;
  public customerGetById(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Customer>>;
  public customerGetById(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Customer>>;
  public customerGetById(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling customerGetById.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Customer>(
      `${this.basePath}/api/v1/customer/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Gets the customers for the carts asynchronously.
   *
   * @param userId The user identifier.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public customerGetCustomersCartsAsync(userId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<CustomerCarts>>;
  public customerGetCustomersCartsAsync(userId: number,
                                        observe?: 'response',
                                        reportProgress?: boolean): Observable<HttpResponse<Array<CustomerCarts>>>;
  public customerGetCustomersCartsAsync(userId: number,
                                        observe?: 'events',
                                        reportProgress?: boolean): Observable<HttpEvent<Array<CustomerCarts>>>;
  public customerGetCustomersCartsAsync(userId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (userId === null || userId === undefined) {
      throw new Error('Required parameter userId was null or undefined when calling customerGetCustomersCartsAsync.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<Array<CustomerCarts>>(`${this.basePath}/api/v1/customers/${encodeURIComponent(String(userId))}/carts`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  async getCustomers(username: string, forceReload?: boolean): Promise<Customer[]> {
    if (!this.customers && forceReload !== true) {
      this.customers = await this.customerGetAllViewable(username).toPromise();
    }
    return of(this.customers).toPromise();
  }

}
