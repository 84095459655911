import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutModule } from './layout/layout.module';

const MODULES: any[] = [
  LayoutModule,
];


export const FEATURES_ROUTES: Routes = [
  { path: 'home', loadChildren: '@app/features/home/home.module#HomeModule' },
  { path: 'login', loadChildren: '@app/features/login/login.module#LoginModule' },
  { path: 'orders', loadChildren: '@app/features/back-office/orders/orders.module#OrdersModule' },
  { path: 'carts', loadChildren: '@app/features/cart/cart.module#CartModule' },
  { path: 'printing', loadChildren: '@app/features/back-office/printing/printing.module#PrintingModule' },
  { path: 'packaging', loadChildren: '@app/features/back-office/packaging/packaging.module#PackagingModule' },
  { path: 'sav', loadChildren: '@app/features/back-office/sav/sav.module#SavModule' },
  { path: 'delivery-racks', loadChildren: '@app/features/back-office/delivery-racks/delivery-racks.module#DeliveryRacksModule' },
  { path: 'users', loadChildren: '@app/features/users/users.module#UsersModule' },
  { path: 'download', loadChildren: '@app/features/download/download.module#DownloadModule' },
  { path: 'privacy', loadChildren: '@app/features/back-office/privacy/privacy.module#PrivacyModule' },
];


@NgModule({
  imports: [
    ...MODULES,
    RouterModule.forChild(FEATURES_ROUTES)
  ],
  exports: MODULES
})
export class FeaturesModule { }
