import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Pipe({ name: 'svghidequotation' })
export class SvgHideQuotationPipe implements PipeTransform {
  /**
   *
   */
  constructor(private http: HttpClient) {
  }

  transform(input: string | any): string | any {
    let result = '';
    if (typeof input !== 'string') {
      return input;
    }

    const headers = new HttpHeaders({
      'Content-Type': 'text/xml',
      'Accept': 'text/xml'
    });

    this.http.get(input, { headers: headers, responseType: 'text' }).
      subscribe(res => {
        result = res.toString();
        result = result
          .replace('<g id="cotations">', '<g id="cotations" visibility="hidden">');
          result = result
          .replace(`<!-- Generator: Adobe Illustrator 22.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->`, '');
          result = result
          .replace(/\n/g, '');
          result = result
          .replace(/\t/g, '');
          result = result
          .replace(/\"/g, '\'');
          result = result
          .replace(/''/g, '\'');

          return result;
      });
  }
}
