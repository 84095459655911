import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-printing-error-dialog',
  templateUrl: './printing-error-dialog.component.html',
  styleUrls: ['./printing-error-dialog.component.scss']
})
export class PrintingErrorDialogComponent implements OnInit {

  @Input()
  visible: boolean;

  @Input()
  title: string;

  @Input()
  message: string;

  constructor() { }

  ngOnInit() {
  }

}
