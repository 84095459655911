import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutComponent } from '@app/features/layout/layout.component';
import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { BreadcrumbService } from '@app/services/breadcrumb.service';
import { Session } from '@app/app.session';
import { AuthenticationService } from '@app/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './layout.topbar.component.html',
})
export class LayoutTopbarComponent implements OnDestroy {

  subscription: Subscription;

  items: MenuItem[];

  constructor(public authService: AuthenticationService,
    public router: Router,
    public app: LayoutComponent,
    public i18n: TranslateService,
    public breadcrumbService: BreadcrumbService,
    public session: Session) {
    this.subscription = breadcrumbService.itemsHandler.subscribe(response => {
      this.items = response;
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
        this.subscription.unsubscribe();
    }
}

  get isMultiLang(): boolean {
    return (this.i18n.langs && this.i18n.langs.length > 1);
  }

  logout() {
    this.authService.unAuthenticated();
    this.router.navigate(['login']);
  }

  onLangChange(selectedLang: string) {
    this.i18n.use(selectedLang);
  }
}
