import { Injectable } from '@angular/core';
import { TokenService } from '@app/services/token.service';
import { Session, AuthToken, LoginInfo } from '@app/app.session';
import { TokenServer } from '@app/models/tokenServer';
import { UserBOService } from './userBO.service';
import { UserBO } from '@app/models/models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private authenticated: boolean;
  private previousPage: string;

  constructor(
    private tokenService: TokenService,
    private session: Session,
    private userBOService: UserBOService
  ) {
    this.authenticated = false;
    this.previousPage = '';
  }

  public setPreviousPage(page: string) {
    this.previousPage = page;
  }

  public getPreviousPage(): string {
    return this.previousPage;
  }

  public unAuthenticated() {
    this.session.setToken(null, this.session.getRemember());
    this.session.connectedUser = null;
    this.session.clearTokenStorage();

    this.authenticated = false;
  }

  public isAuthenticated(): boolean {
    if (this.session == null || this.session.connectedUser == null) {
      this.authenticated = false;
    }

    return this.authenticated;
  }

  private setAuthenticated() {
    this.authenticated = true;
  }

  public canCreateComplaint(): boolean {
    if (this.session && this.session.connectedUser) {
      return this.session.connectedUser.canCreateComplaint;
    }

    return false;
  }

  public isAdministrator(): boolean {
    if (this.session && this.session.connectedUser) {
      return this.session.connectedUser.administrator;
    }

    return false;
  }

  private startTokenRefreshener() {
    if (this.getSessionToken() != null) {
      setInterval(async () => {
        this.refreshToken();
      }, (this.getSessionToken().expirationSeconds / 2) * 1000);
    }
  }

  private async setConnectedUser(username: string, clientId: number) {
    await this.refreshToken();
    this.session.connectedUser = await this.userBOService.userBOGetByUsername(
      username, clientId).toPromise();
  }

  private async refreshToken() {
    const tokenInfo = await this.getToken();

    if (!tokenInfo || tokenInfo == null) {
      return;
    }
    var response = await this.tokenService.tokenRefreshPost('refresh_token', tokenInfo.refresh_token).toPromise() 
      if (response) {
        const date = new Date();
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + response.expires_in);

        this.setNewToken(response.access_token, expirationDate, response.expires_in);
      }
  }

  private setNewToken(token: string, expirationDate: Date, expiresIn: number) {
    const storedToken = this.getSessionToken();
    if (storedToken) {
      storedToken.access_token = token;
      storedToken.expirationDate = expirationDate;
      storedToken.expirationSeconds = expiresIn;

      this.session.setTokenWithCookieCheck(storedToken);
    }
  }

  public async loadAuthentication() {
    const token = await this.getToken();
    if (token) {
      await this.setConnectedUser(token.username, token.clientId);

      this.startTokenRefreshener();
    }
  }

  private async getToken(): Promise<AuthToken> {
    const token = this.getSessionToken();

    if (token && token != null) {
      const now = new Date();

      if (now > token.expirationDate) {
        return null;
      }

      this.session.setTokenWithCookieCheck(token);

      this.setAuthenticated();

      return token;
    }

    return null;
  }

  public async authenticate(loginInfo: LoginInfo): Promise<UserBO> {

    const receivedServerToken = await this.CallTokenService(loginInfo);

    const authInfo = this.buildAuthenticationToken(loginInfo, receivedServerToken);

    this.session.setToken(authInfo, loginInfo.remember);

    await this.setConnectedUser(loginInfo.username, loginInfo.clientId);

    this.setAuthenticated();

    this.startTokenRefreshener();

    return this.session.connectedUser;
  }

  private buildAuthenticationToken(loginInfo: LoginInfo, receivedServerToken: TokenServer) {
    const authInfo = {} as AuthToken;
    authInfo.access_token = receivedServerToken.access_token;
    authInfo.expires_in = receivedServerToken.expires_in;
    authInfo.refresh_token = receivedServerToken.refresh_token;
    authInfo.expirationSeconds = receivedServerToken.expires_in;
    authInfo.username = loginInfo.username;
    authInfo.clientId = loginInfo.clientId;
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + receivedServerToken.expires_in);
    authInfo.expirationDate = expirationDate;

    return authInfo;
  }

  private async CallTokenService(loginInfo: LoginInfo): Promise<TokenServer> {
    return await this.tokenService.tokenPost(loginInfo.grantType,
      loginInfo.username,
      loginInfo.password,
      loginInfo.scope).toPromise();
  }

  private getSessionToken(): AuthToken {
    return (this.session ? this.session.getToken() : null);
  }
}
