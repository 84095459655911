import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NewClaimLine } from '@app/models/newClaimLine';
import { SavAddDetailsComponent } from '@app/features/back-office/sav/sav-add-details/sav-add-details.component';

@Component({
  selector: 'app-sav-add-details-dialog',
  templateUrl: './sav-add-details-dialog.component.html',
  styleUrls: ['./sav-add-details-dialog.component.scss']
})
export class SavAddDetailsDialogComponent implements OnInit {

  @ViewChild('savAddDetailsComponent') savAddDetailsComponent: SavAddDetailsComponent;

  newClaimLine: NewClaimLine;

  // Dialogue visible ou pas
  public dialogVisible = false;

  constructor() { }

  ngOnInit() {
  }

  public show(cl: NewClaimLine) {
    this.savAddDetailsComponent.setNewClaimLine(cl);
    this.dialogVisible = true;
  }
}
