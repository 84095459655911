import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {

  transform(input: string, splitChar: string, splitIndex: number): any {
    if (!input) {
      return '';
    }
    return input.split(splitChar)[splitIndex];
  }
}
