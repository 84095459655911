import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFormat'
})
export class StringFormatPipe implements PipeTransform {

  transform(stringToFormat: any, args?: any): any {
    let theString = stringToFormat;

    for (let cpt = 0; cpt < args.length; cpt++) {
        // "gm" = RegEx options for Global search (more than one instance)
        // and for Multiline search
        const regEx = new RegExp('\\{' + cpt + '\\}', 'gm');

        theString = theString.replace(regEx, args[cpt]);
    }

    return theString;
  }
}
