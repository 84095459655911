import { Component, OnInit, ViewChild } from '@angular/core';
import { ComplaintDetail } from '@app/models/models';

import { SavDetailsComponent } from './../sav-details/sav-details.component';
import { ClaimHeader } from '@app/models/claimHeader';
import { GetClaimPictureRequest } from '@app/models/getClaimPictureRequest';
import { ClaimLineService } from '@app/services/claimLine.service';
import { ClaimPicture } from '@app/models/claimPicture';
import { ClaimService } from '@app/services/claim.service';

@Component({
  selector: 'app-sav-details-dialog',
  templateUrl: './sav-details-dialog.component.html',
  styleUrls: ['./sav-details-dialog.component.scss']
})
export class SavDetailsDialogComponent implements OnInit {

  // Dialogue visible ou pas
  public dialogVisible = false;

  // Data
  public complaintDetail: ComplaintDetail;
  public claimDetail: ClaimHeader;

  pictures: ClaimPicture[] = [];


  // ViewChild
  @ViewChild('savDetailsComponent') savDetailsComponent: SavDetailsComponent;

  constructor(private claimService: ClaimService) { }

  ngOnInit() {
  }

  // public show(cd: ComplaintDetail) {
  //   this.complaintDetail = cd;
  //   this.savDetailsComponent.setComplaintDetails(this.complaintDetail);
  //   this.dialogVisible = true;
  // }

  public show(ch: ClaimHeader) {
    this.claimDetail = ch;
    this.savDetailsComponent.setClaimDetails(this.claimDetail);
    this.dialogVisible = true;

    const request = {
      claimId: this.claimDetail.claimId,
    } as GetClaimPictureRequest;
    this.claimService.claimGetClaimPicturesAsync(request).subscribe(
      res => {
        this.pictures = res;
      },
      error => {
        console.log(error);
      });
  }

}
