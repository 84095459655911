import { NgModule } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

const PROVIDERS: any[] = [
  ConfirmationService
];


@NgModule({
  providers: PROVIDERS
})
export class ServicesModule {}
