import { Component, OnInit, Input } from '@angular/core';
import { ClaimLineDetails } from '@app/models/claimLineDetails';
import { GetClaimPictureRequest } from '@app/models/getClaimPictureRequest';
import { ClaimLineService } from '@app/services/claimLine.service';
import { ClaimPicture } from '@app/models/claimPicture';
import { ClaimService } from '@app/services/claim.service';

@Component({
  selector: 'app-sav-line-details-dialog',
  templateUrl: './sav-line-details-dialog.component.html',
  styleUrls: ['./sav-line-details-dialog.component.scss']
})

export class SavLineDetailsDialogComponent implements OnInit {

  public claimLineDetail: ClaimLineDetails;

  dialogVisible = false;
  total: string;

  pictures: ClaimPicture[] = [];

  constructor(private claimService: ClaimService) { }

  ngOnInit() {
    this.claimLineDetail = {};
  }

  public show(claimLineDetail: ClaimLineDetails) {
    // if (this.claimLineDetail.to) {
    //   this.total = 'partial';
    // } else {
    //   this.total = 'total';
    // }
    this.claimLineDetail = claimLineDetail;
    this.dialogVisible = true;

    const request = {
      claimId: this.claimLineDetail.claimId,
      claimLineId: this.claimLineDetail.sequence
    } as GetClaimPictureRequest;
    this.claimService.claimGetClaimPicturesAsync(request).subscribe(
      res => {
        this.pictures = res;
      },
      error => {
        console.log(error);
      });
  }

  print() {
    console.log('TODO');
    // window.open('#/printing/printing-order-line-details?' +
    // 'year=' + this.orderLineDetail.orderYear +
    // '&orderId=' + this.orderLineDetail.orderSequence +
    // '&seqId=' + this.orderLineDetail.sequence, '_blank');
  }

  onShow(e: any) {
    document.getElementById('claimLineDetailDialog').children[0].scrollTo(0, 0);
  }
}
