import { OrderLineService } from './../../../../../services/orderLine.service';
import { Component, OnInit, Input } from '@angular/core';
import { OrderLine } from '@app/models/models';

@Component({
  selector: 'app-order-line-details-dialog',
  templateUrl: './order-line-details-dialog.component.html',
  styleUrls: ['./order-line-details-dialog.component.scss']
})
export class OrderLineDetailsDialogComponent implements OnInit {

  @Input()
  orderLineDetail: OrderLine;
  dialogVisible = false;
  total: string;

  constructor(private orderLineService: OrderLineService) { }

  ngOnInit() {
    this.orderLineDetail = {};
  }

  public show() {
    if (this.orderLineDetail.partialRemoval) {
      this.total = 'partial';
    } else {
      this.total = 'total';
    }
    this.dialogVisible = true;
  }

  print() {
    window.open('#/printing/printing-order-line-details?' +
    'year=' + this.orderLineDetail.orderYear +
    '&orderId=' + this.orderLineDetail.orderSequence +
    '&seqId=' + this.orderLineDetail.sequence, '_blank');
  }
}
