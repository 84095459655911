import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
    selector: 'app-footer',
    template: `
        <div class="footer">
            <div class="card clearfix">
        <span class="footer-text-left">
            Version : {{ manifest.version }}
            - Build date : {{ manifest.buildDate | date:'dd-MM-y' }}
        </span>
                <span class="footer-text-right">
                    <span class="material-icons ui-icon-copyright"></span>  
                    <span>All Rights Reserved</span></span>
            </div>
        </div>
    `
})
export class LayoutFooterComponent {
    public manifest: any = {};

    constructor(
        private http: HttpClient
    ) { }

    async ngOnInit() {
        this.manifest = await this.http.get('assets/app-manifest.json').toPromise();
    }
}
