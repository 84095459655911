/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { UserBO } from '@app/models/userBO';

import { BASE_PATH, COLLECTION_FORMATS } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';

@Injectable({ providedIn: 'root' })
export class UserBOService {
  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    public appConfig: AppConfig
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   *
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBODeleteUser(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public userBODeleteUser(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public userBODeleteUser(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public userBODeleteUser(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling userBODeleteUser.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.delete<any>(
      `${this.basePath}/api/v1/boUser/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBOEditUser(
    id: number,
    user: UserBO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public userBOEditUser(
    id: number,
    user: UserBO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public userBOEditUser(
    id: number,
    user: UserBO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public userBOEditUser(
    id: number,
    user: UserBO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling userBOEditUser.'
      );
    }
    if (user === null || user === undefined) {
      throw new Error(
        'Required parameter user was null or undefined when calling userBOEditUser.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/api/v1/boUser/${encodeURIComponent(String(id))}`,
      user,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param id
   * @param rights
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBOEditUserRighst(
    id: number,
    rights: Array<number>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public userBOEditUserRighst(
    id: number,
    rights: Array<number>,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public userBOEditUserRighst(
    id: number,
    rights: Array<number>,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public userBOEditUserRighst(
    id: number,
    rights: Array<number>,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling userBOEditUserRighst.'
      );
    }
    if (rights === null || rights === undefined) {
      throw new Error(
        'Required parameter rights was null or undefined when calling userBOEditUserRighst.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(
      `${this.basePath}/api/v1/boUser/${encodeURIComponent(String(id))}/rights`,
      rights,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves the users.
   *
   * @param page The page index.
   * @param clientId Client ID.
   * @param pageSize The page size (20 by default).
   * @param count count the number of rows.
   * @param sort Column to sort.
   * @param sortBy Sort by.
   * @param id User id.
   * @param name User name.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBOGetAll(
    page: number,
    clientId?: number,
    pageSize?: number,
    count?: boolean,
    sort?: string,
    sortBy?: string,
    id?: string,
    name?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public userBOGetAll(
    page: number,
    clientId?: number,
    pageSize?: number,
    count?: boolean,
    sort?: string,
    sortBy?: string,
    id?: string,
    name?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public userBOGetAll(
    page: number,
    clientId?: number,
    pageSize?: number,
    count?: boolean,
    sort?: string,
    sortBy?: string,
    id?: string,
    name?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public userBOGetAll(
    page: number,
    clientId?: number,
    pageSize?: number,
    count?: boolean,
    sort?: string,
    sortBy?: string,
    id?: string,
    name?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec()
    });
    if (clientId !== undefined) {
      queryParameters = queryParameters.set('clientId', <any>clientId);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (sort !== undefined) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (sortBy !== undefined) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (id !== undefined) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (name !== undefined) {
      queryParameters = queryParameters.set('name', <any>name);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<any>(
      `${this.basePath}/api/v1/boUsers/${encodeURIComponent(String(page))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single user.
   *
   * @param id The user ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBOGetById(
    id: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserBO>;
  public userBOGetById(
    id: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserBO>>;
  public userBOGetById(
    id: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserBO>>;
  public userBOGetById(
    id: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling userBOGetById.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserBO>(
      `${this.basePath}/api/v1/boUser/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single user.
   *
   * @param username The username.
   * @param client The client number.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBOGetByUsername(
    username: string,
    client: number,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserBO>;
  public userBOGetByUsername(
    username: string,
    client: number,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserBO>>;
  public userBOGetByUsername(
    username: string,
    client: number,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserBO>>;
  public userBOGetByUsername(
    username: string,
    client: number,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (username === null || username === undefined) {
      throw new Error(
        'Required parameter username was null or undefined when calling userBOGetByUsername.'
      );
    }
    if (client === null || client === undefined) {
      throw new Error(
        'Required parameter client was null or undefined when calling userBOGetByUsername.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.get<UserBO>(
      `${this.basePath}/api/v1/boUser/username/${encodeURIComponent(
        String(username)
      )}/${encodeURIComponent(String(client))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param user
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public userBOPostUser(
    user: UserBO,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<UserBO>;
  public userBOPostUser(
    user: UserBO,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<UserBO>>;
  public userBOPostUser(
    user: UserBO,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<UserBO>>;
  public userBOPostUser(
    user: UserBO,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (user === null || user === undefined) {
      throw new Error(
        'Required parameter user was null or undefined when calling userBOPostUser.'
      );
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected:
      | string
      | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected:
      | string
      | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<UserBO>(
      `${this.basePath}/api/v1/boUsers`,
      user,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
