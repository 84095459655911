import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {map} from 'rxjs/operators';
import {AppConfig} from '@app/models/app-config.model';

@Injectable({
  providedIn: 'root'
})
export class TranslationLoaderService extends TranslateHttpLoader {

  constructor(
    http: HttpClient,
    private appConfig: AppConfig
  ) {
    super(http, appConfig.configServer.translationsUrl, '');
  }

  static convertArray(array: TranslationFromDatabase[]): any {
    return array
      .map(TranslationLoaderService.convert)
      .reduce(TranslationLoaderService.merge, {});
  }

  static merge(result: any, element: any): any {
    return Object.assign(result, element);
  }

  static convert(element: TranslationFromDatabase): any {
    const result = {};
    result[element.translationId] = element.translationValue;
    return result;
  }

  getTranslation(lang: string): any {
    return super.getTranslation(lang).pipe(
      map(TranslationLoaderService.convertArray)
    );
  }
}

class TranslationFromDatabase {
  translationId: string;
  translationValue: string;
}
