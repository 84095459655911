/**
 * SynerWS.WebApi v1
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import {
  HttpClient, HttpHeaders, HttpParams,
  HttpResponse, HttpEvent
} from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '@app/core/encoder';

import { Observable } from 'rxjs/Observable';

import { AddPictureToComplaintString } from '@app/models/addPictureToComplaintString';
import { ComplaintDetail } from '@app/models/complaintDetail';
import { ComplaintWithPictures } from '@app/models/complaintWithPictures';
import { Picture } from '@app/models/picture';
import { UpdateComplaint } from '@app/models/updateComplaint';

import { BASE_PATH } from '@app/core/variables';
import { Configuration } from '@app/core/configuration';

import { AppConfig } from '@app/models/app-config.model';

@Injectable({ providedIn: 'root' })
export class ComplaintService {

  // protected basePath = 'http://pc1025/SynerWS.WebApi/';
  protected basePath: string = this.appConfig.configServer.boServerPath;
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration,
    public appConfig: AppConfig) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }



  /**
   * Retrieves the complaints of a client.
   *
   * @param page The page index.
   * @param customerId The client ID.
   * @param customers The list of customers.
   * @param pageSize The page size (20 by default).
   * @param count count the number of rows.
   * @param id The complaint id.
   * @param name The complainer name.
   * @param period Period for the complaints..
   * @param visited Show only visited complaints.
   * @param closed Show only closed complaints.
   * @param sort
   * @param sortBy
   * @param languageCode
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintGetAll(
    page: number, customerId?: number, customers?: string, pageSize?: number, count?: boolean, id?: number, name?: string,
    period?: number, visited?: boolean, closed?: boolean, sort?: string, sortBy?: string, languageCode?: string,
    observe?: 'body', reportProgress?: boolean): Observable<any>;
  public complaintGetAll(
    page: number, customerId?: number, customers?: string, pageSize?: number, count?: boolean, id?: number, name?: string,
    period?: number, visited?: boolean, closed?: boolean, sort?: string, sortBy?: string, languageCode?: string,
    observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public complaintGetAll(
    page: number, customerId?: number, customers?: string, pageSize?: number, count?: boolean, id?: number, name?: string,
    period?: number, visited?: boolean, closed?: boolean, sort?: string, sortBy?: string, languageCode?: string,
    observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public complaintGetAll(
    page: number, customerId?: number, customers?: string, pageSize?: number, count?: boolean, id?: number, name?: string,
    period?: number, visited?: boolean, closed?: boolean, sort?: string, sortBy?: string, languageCode?: string,
    observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (page === null || page === undefined) {
      throw new Error('Required parameter page was null or undefined when calling complaintGetAll.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (customerId !== undefined) {
      queryParameters = queryParameters.set('customerId', <any>customerId);
    }
    if (customers !== undefined) {
      queryParameters = queryParameters.set('customers', <any>customers);
    }
    if (pageSize !== undefined) {
      queryParameters = queryParameters.set('pageSize', <any>pageSize);
    }
    if (count !== undefined) {
      queryParameters = queryParameters.set('count', <any>count);
    }
    if (id !== undefined) {
      queryParameters = queryParameters.set('id', <any>id);
    }
    if (name !== undefined) {
      queryParameters = queryParameters.set('name', <any>name);
    }
    if (period !== undefined) {
      queryParameters = queryParameters.set('period', <any>period);
    }
    if (visited !== undefined) {
      queryParameters = queryParameters.set('visited', <any>visited);
    }
    if (closed !== undefined) {
      queryParameters = queryParameters.set('closed', <any>closed);
    }
    if (sort !== undefined) {
      queryParameters = queryParameters.set('sort', <any>sort);
    }
    if (sortBy !== undefined) {
      queryParameters = queryParameters.set('sortBy', <any>sortBy);
    }
    if (languageCode !== undefined) {
      queryParameters = queryParameters.set('languageCode', <any>languageCode);
    }
    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<any>(`${this.basePath}/api/v1/complaints/${encodeURIComponent(String(page))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves detailed information on a single complaint.
   *
   * @param id The complaint ID.
   * @param includePhotos Include the photos.
   * @param languageCode Language ncode.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintGetById(
    id: number, includePhotos?: boolean, languageCode?: string, observe?: 'body', reportProgress?: boolean):
    Observable<ComplaintDetail>;
  public complaintGetById(
    id: number, includePhotos?: boolean, languageCode?: string, observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<ComplaintDetail>>;
  public complaintGetById(
    id: number, includePhotos?: boolean, languageCode?: string, observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<ComplaintDetail>>;
  public complaintGetById(
    id: number, includePhotos?: boolean, languageCode?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling complaintGetById.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (includePhotos !== undefined) {
      queryParameters = queryParameters.set('includePhotos', <any>includePhotos);
    }
    if (languageCode !== undefined) {
      queryParameters = queryParameters.set('languageCode', <any>languageCode);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<ComplaintDetail>(`${this.basePath}/api/v1/complaint/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves a photo from a complaint.
   *
   * @param complaintId The complaint ID.
   * @param photoId The photo ID.
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintGetPhoto(
    complaintId: number, photoId: number, id: string, observe?: 'body', reportProgress?: boolean): Observable<Picture>;
  public complaintGetPhoto(
    complaintId: number, photoId: number, id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Picture>>;
  public complaintGetPhoto(
    complaintId: number, photoId: number, id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Picture>>;
  public complaintGetPhoto(
    complaintId: number, photoId: number, id: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (complaintId === null || complaintId === undefined) {
      throw new Error('Required parameter complaintId was null or undefined when calling complaintGetPhoto.');
    }
    if (photoId === null || photoId === undefined) {
      throw new Error('Required parameter photoId was null or undefined when calling complaintGetPhoto.');
    }
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling complaintGetPhoto.');
    }

    let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
    if (photoId !== undefined) {
      queryParameters = queryParameters.set('photoId', <any>photoId);
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<Picture>(
      `${this.basePath}/api/v1/complaint/${encodeURIComponent(String(complaintId))}/photo/${encodeURIComponent(String(id))}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Retrieves a photo from a complaint.
   *
   * @param complaintId The complaint ID.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintGetPhotos(complaintId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public complaintGetPhotos(complaintId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public complaintGetPhotos(complaintId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public complaintGetPhotos(complaintId: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (complaintId === null || complaintId === undefined) {
      throw new Error('Required parameter complaintId was null or undefined when calling complaintGetPhotos.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<any>(`${this.basePath}/api/v1/complaint/${encodeURIComponent(String(complaintId))}/photos`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Saves a new complaint.
   *
   * @param entity The complaint.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintPost(entity: ComplaintWithPictures, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public complaintPost(entity: ComplaintWithPictures, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public complaintPost(entity: ComplaintWithPictures, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public complaintPost(entity: ComplaintWithPictures, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling complaintPost.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/complaint`,
      entity,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Adds a photo to a complaint.
   *
   * @param id The complaint ID.
   * @param parametersString The operation parameters.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintPostPhoto(
    id: number, parametersString: AddPictureToComplaintString, observe?: 'body', reportProgress?: boolean):
    Observable<any>;
  public complaintPostPhoto(
    id: number, parametersString: AddPictureToComplaintString, observe?: 'response', reportProgress?: boolean):
    Observable<HttpResponse<any>>;
  public complaintPostPhoto(
    id: number, parametersString: AddPictureToComplaintString, observe?: 'events', reportProgress?: boolean):
    Observable<HttpEvent<any>>;
  public complaintPostPhoto(
    id: number, parametersString: AddPictureToComplaintString, observe: any = 'body', reportProgress: boolean = false):
    Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling complaintPostPhoto.');
    }
    if (parametersString === null || parametersString === undefined) {
      throw new Error('Required parameter parametersString was null or undefined when calling complaintPostPhoto.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.post<any>(`${this.basePath}/api/v1/complaint/${encodeURIComponent(String(id))}/photo`,
      parametersString,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Updates an existing complaint.
   *
   * @param id The complaint ID.
   * @param entity The complaint.
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintPut(id: number, entity: UpdateComplaint, observe?: 'body', reportProgress?: boolean): Observable<any>;
  public complaintPut(id: number, entity: UpdateComplaint, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public complaintPut(id: number, entity: UpdateComplaint, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public complaintPut(id: number, entity: UpdateComplaint, observe: any = 'body', reportProgress: boolean = false): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error('Required parameter id was null or undefined when calling complaintPut.');
    }
    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling complaintPut.');
    }

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json',
      'text/json',
      'text/html',
      'application/xml',
      'text/xml',
      'application/x-www-form-urlencoded'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected !== undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.put<any>(`${this.basePath}/api/v1/complaint/${encodeURIComponent(String(id))}`,
      entity,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   *
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public complaintTestComplaint(observe?: 'body', reportProgress?: boolean): Observable<any>;
  public complaintTestComplaint(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
  public complaintTestComplaint(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
  public complaintTestComplaint(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

    let headers = this.defaultHeaders;

    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected !== undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }


    return this.httpClient.get<any>(`${this.basePath}/api/v1/complaint/test`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

}
