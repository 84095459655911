import { OrdersDetailsDialogComponent } from './../features/back-office/orders/orders-details-dialog/orders-details-dialog.component';
import { OrderLineDetailsDialogComponent } from './../features/back-office/orders/orders-line/order-line-details-dialog/order-line-details-dialog.component';
import { PrintingErrorDialogComponent } from './../features/back-office/printing/printing-error-dialog/printing-error-dialog.component';
import { OrdersLineDetailsComponent } from './../features/back-office/orders/orders-line/orders-line-details/orders-line-details.component';
// Angular Modules
import {InjectionToken, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from 'primeng/table';
import {OwlModule} from 'ngx-owl-carousel';
// Pipes
import {PropercasePipe} from './pipes/propercase.pipe';
import {SvgHideQuotationPipe} from './pipes/svghidequotation.pipe';
import {StringFormatPipe} from './pipes/stringFormat.pipe';
// Components
import {PageHeaderComponent} from './components/page-header.component';
import * as PrimeNG from 'primeng/primeng';
import {CalendarTranslatorService} from '@app/services/calendar-translator.service';
import {CheckViewerComponent} from '@app/shared/components/check-viewer.component';
import {ReadMoreComponent} from '@app/shared/components/read-more.component';
import {ServicesModule} from '@app/core/core.service';
import {CartLineEditDrawComponent} from '@app/shared/components/cart-line-edit-draw/cart-line-edit-draw.component';
import { SplitPipe } from './pipes/split.pipe';
import { OrdersDetailsComponent } from '@app/features/back-office/orders/orders-details/orders-details.component';
import { TableOrdersComponent } from './components/table-orders/table-orders.component';
import { PackagingTableComponent } from './components/packaging-table/packaging-table.component';
import { SavDetailsDialogComponent } from '@app/features/back-office/sav/sav-details-dialog/sav-details-dialog.component';
import { SavDetailsComponent } from '@app/features/back-office/sav/sav-details/sav-details.component';

import { NgxBarcodeModule } from 'ngx-barcode';
import { DeliveryRacksTableComponent } from './components/delivery-racks-table/delivery-racks-table.component';
import { DeliveryRacksReleaseDialogComponent } from './components/delivery-racks-release-dialog/delivery-racks-release-dialog.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { FileUploadModule } from 'primeng/primeng';
import { ErrorDialogComponent } from './components/error-dialog/error-dialog.component';
import { TableSavComponent } from './components/table-sav/table-sav.component';
import { SavLineDetailsComponent } from '@app/features/back-office/sav/sav-line/sav-line-details/sav-line-details.component';
import { SavLineDetailsDialogComponent } from '@app/features/back-office/sav/sav-line/sav-line-details-dialog/sav-line-details-dialog.component';
import { SavAddDetailsDialogComponent } from '@app/features/back-office/sav/sav-add-details-dialog/sav-add-details-dialog.component';
import { SavAddDetailsComponent } from '@app/features/back-office/sav/sav-add-details/sav-add-details.component';

const MODULES: any[] = [
  CommonModule,
  FormsModule,
  HttpClientModule,
  RouterModule,
  TranslateModule,
  TableModule,
  ServicesModule,
  PrimeNG.AccordionModule,
  PrimeNG.AutoCompleteModule,
  PrimeNG.ButtonModule,
  PrimeNG.CalendarModule,
  PrimeNG.CardModule,
  PrimeNG.CheckboxModule,
  PrimeNG.ConfirmDialogModule,
  PrimeNG.ContextMenuModule,
  PrimeNG.DialogModule,
  PrimeNG.DropdownModule,
  PrimeNG.FieldsetModule,
  PrimeNG.GrowlModule,
  PrimeNG.InputMaskModule,
  PrimeNG.InputSwitchModule,
  PrimeNG.InputTextareaModule,
  PrimeNG.InputTextModule,
  PrimeNG.MenuModule,
  PrimeNG.PaginatorModule,
  PrimeNG.PanelModule,
  PrimeNG.RadioButtonModule,
  PrimeNG.ScrollPanelModule,
  PrimeNG.StepsModule,
  PrimeNG.TooltipModule,
  PrimeNG.TriStateCheckboxModule,
  OwlModule,
  PrimeNG.GMapModule,
  PrimeNG.ProgressSpinnerModule,
  NgxBarcodeModule,
  FileUploadModule
];

const DECLARATIONS = [
  PropercasePipe,
  SvgHideQuotationPipe,
  StringFormatPipe,
  PageHeaderComponent,
  CheckViewerComponent,
  ReadMoreComponent,
  CartLineEditDrawComponent,
  SplitPipe,
  OrdersLineDetailsComponent,
  OrdersDetailsComponent,
  PrintingErrorDialogComponent,
  TableOrdersComponent,
  OrderLineDetailsDialogComponent,
  OrdersDetailsDialogComponent,
  TableSavComponent,
  PackagingTableComponent,
  SavDetailsDialogComponent,
  SavDetailsComponent,
  DeliveryRacksTableComponent,
  DeliveryRacksReleaseDialogComponent,
  UsersTableComponent,
  ErrorDialogComponent,
  SavLineDetailsComponent,
  SavLineDetailsDialogComponent,
  SavAddDetailsComponent,
  SavAddDetailsDialogComponent
];

export const CALENDAR_LOCALE = new InjectionToken<any>('CALENDAR_LOCALE');

export function getLocale(calendarTranslatorService: CalendarTranslatorService) {
  return calendarTranslatorService.instant();
}

@NgModule({
  imports: MODULES,
  declarations: DECLARATIONS,
  exports: [...MODULES, ...DECLARATIONS],
  providers: [
    {
      provide: CALENDAR_LOCALE,
      useFactory: (getLocale),
      deps: [CalendarTranslatorService]
    }]
})
export class SharedModule {
}
