import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { SavTableService } from '@app/services/sav-table.service';
import { SavFilters } from './../../../models/filters/savFilters';
import { ComplaintService } from '@app/services/complaint.service';
import { ComplaintDetail, Customer } from '@app/models/models';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SavDetailsDialogComponent } from '@app/features/back-office/sav/sav-details-dialog/sav-details-dialog.component';
import { LazyLoadEvent } from 'primeng/api';
import { ExportService } from '@app/services/export.service';
import { Session } from '@app/app.session';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ClaimService } from '@app/services/claim.service';
import { SearchClaimRequest } from '@app/models/searchClaimRequest';
import { ClaimHeader } from '@app/models/claimHeader';
import { ClaimLineService } from '@app/services/claimLine.service';
import { ClaimLineDetails } from '@app/models/claimLineDetails';
import { DataTable } from 'primeng/components/datatable/datatable';
import { SavLineDetailsDialogComponent } from '@app/features/back-office/sav/sav-line/sav-line-details-dialog/sav-line-details-dialog.component';
import { NewClaim } from '@app/models/newClaim';
import { NewClaimLine } from '@app/models/newClaimLine';
import { ClaimPicture } from '@app/models/claimPicture';

@Component({
  selector: 'app-table-sav',
  templateUrl: './table-sav.component.html',
  styleUrls: ['./table-sav.component.scss'],
  animations: [
    trigger('rowExpansionTrigger', [
      state('void', style({
        transform: 'translateX(-10%)',
        opacity: 0
      })),
      state('active', style({
        transform: 'translateX(0)',
        opacity: 1
      })),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class TableSavComponent implements OnInit {

  @Input() public complaintDetails: ComplaintDetail[];

  @Input() public claimHeader: ClaimHeader[];

  // @Input() public savFilters: SavFilters;

  @Input() public searchClaimRequest: SearchClaimRequest;

  @Input() public loading = false;

  @Input() public totalRecords: number;

  @Input() public customers: Customer[];

  @Input() public printingMode = false;

  @Input() public complainant: string;

  // ViewChild
  @ViewChild('savDetailsDialogComponent') savDetailsDialogComponent: SavDetailsDialogComponent;

  @ViewChild('searchOrdersLinesDataTable') public searchOrdersLinesDataTable: DataTable;

  @ViewChild('savLineDetailsDialogComponent') savLineDetailsDialogComponent: SavLineDetailsDialogComponent;

  //public selectedComplaintDetail: ComplaintDetail;
  public selectedClaimArray: ClaimLineDetails[];
  public selectedClaimLineDetail: ClaimLineDetails;

  public pageSize = 10;

  // Printing
  public printingErrorDialogVisible = false;
  public printingErrorDialogTitle: string;
  public printingErrorDialogMessage: string;

  // Recherche
  public pageNumber = 1;

  // SearchLaunchedOnce
  public searchLaunchedOnce = false;

  public headers: any[];
  columns: any[];

  public expandAll: Boolean = false;

  public get newSav(): NewClaim {
    return this.claimService.newSav;
  }

  public set newSav(value: NewClaim) {
    this.claimService.newSav = value;
  }

  constructor(private complaintService: ComplaintService,
    private savTableService: SavTableService,
    private translateService: TranslateService,
    public tableSavService: SavTableService,
    private claimService: ClaimService,
    private claimLineService: ClaimLineService,
    private exportService: ExportService,
    public session: Session) {
    this.translateService.onLangChange.subscribe(this.onLangChange.bind(this));

    if (this.session && this.session.connectedUser && this.session.connectedUser.languageCode) {
      this.translateService.use(this.session.connectedUser.languageCode);
    }
  }

  onLangChange(langE: LangChangeEvent) {
    this.translate();
  }

  translate() {
    this.columns =
      [
        { field: 'id', header: this.translateService.instant('COMPLAINT_ID') },
        { field: 'complainant', header: this.translateService.instant('COMPLAINT_COMPLAINANT') },
        { field: 'complaintType', header: this.translateService.instant('COMPLAINT_COMMENT') },
        { field: 'complainantPhone', header: this.translateService.instant('COMPLAINT_PHONE') },
        { field: 'openingDate', header: this.translateService.instant('COMPLAINT_DATE') },
        { field: 'visitDate', header: this.translateService.instant('COMPLAINT_VISIT') },
        { field: 'closureDate', header: this.translateService.instant('COMPLAINT_CLOSURE_DATE') }
      ];
  }

  ngOnInit() {
    this.translate();
    this.newSav = {} as NewClaim;
    this.newSav.claimLines = [] as NewClaimLine[];
  }

  public detailsShow(claim: ClaimHeader) {
    // this.complaintService.complaintGetById(id).subscribe(
    //   (result) => {
    //     this.selectedComplaintDetail = result;
    //     this.savDetailsDialogComponent.show(this.selectedComplaintDetail);
    //   }, (err) => {
    //     console.log(err);
    //   }
    // );

    this.savDetailsDialogComponent.show(claim);
  }

  public detailsLineShow(claimLineDetail: ClaimLineDetails) {
    this.selectedClaimLineDetail = claimLineDetail;
    this.savLineDetailsDialogComponent.show(this.selectedClaimLineDetail);
  }

  public searchPage(event: any) {
    // if (event && event.rows) {
    //   this.pageSize = event.rows;
    // }

    // // init savFilters
    // if (this.savFilters) {
    //   this.loading = true;

    //   this.savFilters.pageSize = this.pageSize;

    //   this.savTableService.searchPage(event, this.savFilters, this.pageNumber, this.loading, this.customers, false).subscribe(
    //     (result) => {
    //       this.complaintDetails = result.entities;
    //       this.loading = false;
    //       this.totalRecords = result.total;
    //     }, (err) => {
    //       this.loading = false;
    //       console.log(err);
    //     });


    // }

    if (event && event.rows) {
      this.pageSize = event.rows;
    }
    this.pageNumber = event.page + 1;

    // init savFilters

    if (this.searchClaimRequest) {
      this.loading = true;

      this.searchClaimRequest.languageCode = this.session.connectedUser.languageCode;
      this.searchClaimRequest.pageSize = this.pageSize;
      this.searchClaimRequest.pageNumber = this.pageNumber;
      this.searchClaimRequest.sort = 'claimId';
      this.searchClaimRequest.sortBy = 'desc';
      this.searchClaimRequest.customerId = this.session.connectedUser.clientId;
      this.searchClaimRequest.complainant = this.complainant && this.complainant.trim().length > 0 ? this.complainant : undefined;

      this.savTableService.searchPage(this.searchClaimRequest, this.pageNumber, this.loading, this.customers, false).subscribe(
        (result) => {
          this.claimHeader = result.map(c => {
            c.claimLineDetails = [];
            this.collapseAllRows(this.searchOrdersLinesDataTable);
            return c;
          });
          this.totalRecords = result.length > 0 ? result[0].totalRecords : 0;
          this.loading = false;
        }, (err) => {
          this.loading = false;
          console.log(err);
        });

      // this.claimService.claimSearchClaimsAsync(this.searchClaimRequest).subscribe(
      //   (result) => {
      //     this.claimHeader = result.map(c => {
      //       c.claimLineDetails = [];
      //       this.collapseAllRows(this.searchOrdersLinesDataTable);
      //       return c;
      //     });
      //     this.totalRecords = result.length > 0 ? result[0].totalRecords : 0;
      //     this.loading = false;
      //   }, (err) => {
      //     console.log(err);
      //     this.loading = false;
      //   }
      // );
    }
  }

  customSort(event: LazyLoadEvent) {
    if (!event.sortField) { return; }
    if (event.sortOrder === 1) {
      // this.savFilters.sortBy = 'asc';
      this.searchClaimRequest.sortBy = 'asc';
    } else {
      // this.savFilters.sortBy = 'desc';
      this.searchClaimRequest.sortBy = 'desc';
    }
    if (event.sortField === 'complaintType') {
      // this.savFilters.sort = 'complaintTypedescription';
      this.searchClaimRequest.sort = 'complaintTypedescription';
    } else {
      // this.savFilters.sort = event.sortField;
      this.searchClaimRequest.sort = event.sortField;
    }

    this.searchPage({ page: 0 });
  }

  // exportExcel() {
  //   this.loading = true;
  //   this.searchLaunchedOnce = true;
  //   const allDetails: any[] = [];

  //   this.headers = this.tableSavService.initHeader();

  //   const totRecords: number = this.totalRecords ? this.totalRecords : this.totalRecords;

  //   this.tableSavService.excelExport(this.searchLaunchedOnce, this.customers, totRecords).subscribe(
  //     (r) => {
  //       r.entities.forEach(e => {
  //         const obj = {
  //           id: e.id,
  //           warrantyStatus: e.warrantyStatus ? 'OUI' : 'NON',
  //           complainant: e.complainant,
  //           description: e.complaintType.description,
  //           complainantPhone: e.complainantPhone,
  //           openingDate: e.openingDate,
  //           visitDate: e.visitDate,
  //           closureDate: e.closureDate
  //         };
  //         allDetails.push(obj);
  //       });
  //       this.loading = false;
  //       this.exportService.exportToXlxs(this.headers, allDetails, 'Sheet1', 'Export.xlsx');
  //     }, (err) => {
  //       console.log(err);
  //     });

  // }


  exportExcel() {

    this.translateService.instant('COMPLAINT_ID'),
    this.translateService.instant('COMPLAINT_WARRANTY'),
    this.translateService.instant('COMPLAINT_COMPLAINANT'),
    this.translateService.instant('COMPLAINT_COMMENT'),
    this.translateService.instant('COMPLAINT_PHONE'),
    this.translateService.instant('COMPLAINT_DATE'),
    this.translateService.instant('COMPLAINT_VISIT'),
    this.translateService.instant('COMPLAINT_CLOSURE_DATE')

    const headers = [
      [
        this.translateService.instant('COMPLAINT_ID'),
        this.translateService.instant('COMPLAINT_WARRANTY'),
        this.translateService.instant('COMPLAINT_COMPLAINANT'),
        this.translateService.instant('COMPLAINT_COMMENT'),
        this.translateService.instant('COMPLAINT_PHONE'),
        this.translateService.instant('COMPLAINT_DATE'),
        this.translateService.instant('COMPLAINT_VISIT'),
        this.translateService.instant('COMPLAINT_CLOSURE_DATE'),
        this.translateService.instant('COMPLAINT_ORDER_ID'),
        this.translateService.instant('GLASS_ID'),
        this.translateService.instant('DELIVERY_DATE'),
        this.translateService.instant('NEW_COMPLAINT_RACKID'),
      ]
    ];

    const allDetails: any[] = [];
    this.searchLaunchedOnce = true;
    this.loading = true;

    this.searchClaimRequest.customerId = this.session.connectedUser.clientId;
    this.searchClaimRequest.pageNumber = this.searchClaimRequest.pageNumber ? this.searchClaimRequest.pageNumber : 1;
    this.searchClaimRequest.pageSize = this.totalRecords;
    this.searchClaimRequest.complainant = this.complainant && this.complainant.trim().length > 0 ? this.complainant : undefined;
    this.searchClaimRequest.sort = 'claimId';
    this.searchClaimRequest.sortBy = 'desc';

    // Get all orders
    this.savTableService.searchPage(this.searchClaimRequest, this.pageNumber, this.loading, this.customers, false)
      .subscribe(claims => {
        // For each order, get detail
        let i = 0;
        let j = 0;
        claims.forEach(claim => {
          const request = {
            claimId: claim.claimId,
            sort: 'sequence',
            sortBy: 'desc',
            languageCode: this.session.connectedUser.languageCode
          };
          this.claimLineService.claimLineGetClaimLinesDetailsAsync(request).subscribe(
              d => {
                j = 0;
                d.forEach(detail => {
                  const obj = {
                    id: claim.claimId,
                    warrantyStatus: detail.warrantyStatus ?
                      this.translateService.instant('YES') : this.translateService.instant('NO') ,
                    complainant: claim.complainant,
                    description: claim.description,
                    complainantPhone: claim.complainantPhone,
                    openingDate: claim.openingDate,
                    visitDate: claim.visitDate,
                    closureDate: claim.closureDate,
                    orderLineId: detail.defectiveOrderLineId,
                    glassLineId: detail.defectiveGlassGlassId,
                    deliveryDate: detail.defectiveDeliveryDate,
                    rackId: detail.defectiveRackId,
                  };
                  allDetails.push(obj);
                  j++;
                });
                i++;
                if (i === claims.length && j === d.length) {
                  this.exportService.exportToXlxs(headers, allDetails, 'Sheet1', 'Export.xlsx');
                  this.loading = false;
                }
              }, (err) => {
                console.log(err);
              });
        });
      }, (err) => {
        console.log(err);
      });
  }


  public print() {
    //const complaintDetail = this.complaintDetails;

    // if (complaintDetail.length > 0) {
    //   let url = '';
    //   if (this.savFilters !== null) {
    //     // Set page size to total entities number
    //     this.savFilters.pageSize = this.totalRecords;
    //     Object.keys(this.savFilters).forEach(prop => {
    //       if (this.savFilters[prop] !== null && this.savFilters[prop] !== undefined) {
    //         url += '&' + prop + '=' + this.savFilters[prop];
    //       }
    //     });

    //     url += '&' + 'clientId' + '=' + this.session.connectedUser.clientId;
    //     url += '&' + 'lang' + '=' + this.session.connectedUser.languageCode;
    //     url += '&' + 'total' + '=' + this.totalRecords;
    //   }

    //   window.open('#/printing/printing-sav?' + url, '_blank');
    // } else {
    //   this.printingErrorDialogTitle = this.translateService.instant('ERROR');
    //   this.printingErrorDialogMessage = this.translateService.instant('NO_RESULTS');
    //   this.printingErrorDialogVisible = true;
    // }

    const claimHeader = this.claimHeader;
    if (claimHeader.length > 0) {
      let url = '';
      if (this.searchClaimRequest !== null) {
        // Set page size to total entities number
        this.searchClaimRequest.pageSize = this.totalRecords;
        Object.keys(this.searchClaimRequest).forEach(prop => {
          if (this.searchClaimRequest[prop] !== null && this.searchClaimRequest[prop] !== undefined) {
            url += '&' + prop + '=' + this.searchClaimRequest[prop];
          }
        });

        url += '&' + 'clientId' + '=' + this.session.connectedUser.clientId;
        url += '&' + 'lang' + '=' + this.session.connectedUser.languageCode;
        url += '&' + 'total' + '=' + this.totalRecords;
      }

      window.open('#/printing/printing-sav?' + url, '_blank');
    } else {
      this.printingErrorDialogTitle = this.translateService.instant('ERROR');
      this.printingErrorDialogMessage = this.translateService.instant('NO_RESULTS');
      this.printingErrorDialogVisible = true;
    }
  }

  public loadDetails(ch: ClaimHeader, i: number) {
    this.loading = true;
    const request = {
      claimId: ch.claimId,
      sort: 'sequence',
      sortBy: 'desc',
      languageCode: this.session.connectedUser.languageCode
    };
    this.claimLineService.claimLineGetClaimLinesDetailsAsync(request).subscribe(
      (result) => {
        this.claimHeader[i].claimLineDetails = result;
        this.loading = false;
      },
      (error) => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  public expandAllRows(searchOrdersLinesDataTable: any) {
    if (this.claimHeader) {
      this.claimHeader.forEach(
        (ch, i) => {
          this.loading = true;
          // this.orderLineService.orderLineGetAll(this.session.connectedUser ? this.session.connectedUser.clientId : undefined,
          //   undefined,
          //   sod.orderYear ? sod.orderYear : undefined,
          //   sod.orderSequence ? sod.orderSequence.toString() : undefined,
          //   undefined,
          //   this.barcode ? (this.filterOrders.barcode ? this.filterOrders.barcode : undefined) : undefined)
          //   .subscribe(
          //     r => {
          //       this.searchOrdersLines = r;
          //       this.searchOrderDetails[i].orderLine = this.searchOrdersLines;
          //       this.loading = false;
          //     }, (err) => {
          //       this.loading = false;
          //       console.log(err);
          //     }
          //   );
          const request = {
            claimId: ch.claimId,
            sort: 'sequence',
            sortBy: 'desc'
          };
          this.claimLineService.claimLineGetClaimLinesDetailsAsync(request).subscribe(
            (result) => {
              this.claimHeader[i].claimLineDetails = result;
              this.loading = false;
            },
            (error) => {
              console.log(error);
              this.loading = false;
            }
          );

          this.expandAll = true;
          searchOrdersLinesDataTable.expandedRowKeys[ch.claimId] = 1;
        }
      );
    }
  }

  public collapseAllRows(searchOrdersLinesDataTable: any) {
    this.expandAll = false;
    searchOrdersLinesDataTable.expandedRowKeys = {};
  }
}
