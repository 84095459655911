import { Component, OnInit, Input } from '@angular/core';
import { ClaimLineDetails } from '@app/models/claimLineDetails';
import { DatePipe } from '@angular/common';
import { ClaimLineService } from '@app/services/claimLine.service';
import { GetClaimPictureRequest } from '@app/models/getClaimPictureRequest';
import { ClaimPicture } from '@app/models/claimPicture';

@Component({
  selector: 'app-sav-line-details',
  templateUrl: './sav-line-details.component.html',
  styleUrls: ['./sav-line-details.component.scss'],
  providers: [DatePipe],
})

export class SavLineDetailsComponent implements OnInit {

  @Input()
  public claimLineDetail: ClaimLineDetails;

  @Input()
  total: string;

  @Input()
  pictures: ClaimPicture[] = [];

  constructor(public datepipe: DatePipe,
              private claimLineService: ClaimLineService ) { }

  ngOnInit() {
  }

  public getDeliveryDate(claimLineDetail: ClaimLineDetails): string {
    let result = '';
    if (claimLineDetail && claimLineDetail.defectiveDeliveryDate) {
        this.datepipe.transform(claimLineDetail.defectiveDeliveryDate, 'dd/MM/yyyy') === '01/01/1900' ?
            result += '' : result += this.datepipe.transform(claimLineDetail.defectiveDeliveryDate, 'dd/MM/yyyy');
    }
    return result;
  }

  public getWarrantyDeliveryDate(claimLineDetail: ClaimLineDetails): string {
    let result = '';
    if (claimLineDetail && claimLineDetail.warrantyDeliveryDate) {
        this.datepipe.transform(claimLineDetail.warrantyDeliveryDate, 'dd/MM/yyyy') === '01/01/1900' ?
            result += '' : result += this.datepipe.transform(claimLineDetail.warrantyDeliveryDate, 'dd/MM/yyyy');
    }
    return result;
  }

  public getVisitDate(claimLineDetail: ClaimLineDetails): string {
    let result = '';
    if (claimLineDetail && claimLineDetail.visitDate) {
        this.datepipe.transform(claimLineDetail.visitDate, 'dd/MM/yyyy') === '01/01/1900' ?
            result += '' : result += this.datepipe.transform(claimLineDetail.visitDate, 'dd/MM/yyyy');
    }
    return result;
  }

  public getClosureDate(claimLineDetail: ClaimLineDetails): string {
    let result = '';
    if (claimLineDetail && claimLineDetail.closureDate) {
        this.datepipe.transform(claimLineDetail.closureDate, 'dd/MM/yyyy') === '01/01/1900' ?
            result += '' : result += this.datepipe.transform(claimLineDetail.closureDate, 'dd/MM/yyyy');
    }
    return result;
  }

  public getOpeningDate(claimLineDetail: ClaimLineDetails): string {
    let result = '';
    if (claimLineDetail && claimLineDetail.openingDate) {
        this.datepipe.transform(claimLineDetail.openingDate, 'dd/MM/yyyy') === '01/01/1900' ?
            result += '' : result += this.datepipe.transform(claimLineDetail.openingDate, 'dd/MM/yyyy');
    }
    return result;
  }

  public openPicture(p: any) {

    // tslint:disable-next-line:max-line-length
    const iframe = '<iframe src="data:image/png;base64,' + p + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>';
    const x = window.open();
    x.document.open();
    x.document.write(iframe);
    x.document.close();
}
}

