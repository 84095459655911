import { Injectable } from '@angular/core';
import { UserBO } from '@app/models/userBO';
import { LocaleSettings } from 'primeng/primeng';
import { LocalStorage } from 'ngx-webstorage';
import { CookieService } from 'ngx-cookie';
import { TokenServerQuery } from './models/tokenServerQuery';

@Injectable({ providedIn: 'root' })
export class Session {
  public connectedUser: UserBO;

  public dateFormat: string;
  public localeSettings: LocaleSettings;

  private remember: boolean;

  private tokenServerQuery: TokenServerQuery;

  constructor(private cookieService: CookieService) {
    this.remember = false;
  }

  @LocalStorage()
  private tokenInStorage: AuthToken;

  clearTokenStorage() {
    this.tokenInStorage = null;
  }

  getRemember(): boolean {
    return this.remember;
  }

  setTokenWithCookieCheck(receivedToken: AuthToken) {
    this.tokenInStorage = receivedToken;

    this.remember = false;

    if (receivedToken && this.cookieService.getObject('SynerAuth')) {
      this.remember = true;
      this.cookieService.putObject('SynerAuth', receivedToken, { expires: receivedToken.expirationDate });
    }
  }

  setToken(receivedToken: AuthToken, rememberMe: boolean) {
    this.tokenInStorage = receivedToken;

    this.remember = rememberMe;

    if (rememberMe && receivedToken) {
      this.cookieService.putObject('SynerAuth', receivedToken, { expires: receivedToken.expirationDate });
    } else {
      if (!rememberMe) {
        this.cookieService.remove('SynerAuth');
      }
    }
  }

  getToken(): AuthToken {
    this.buildTokenServerQueryFromBase64QueryParameter();

    let token = this.tokenInStorage;

    if (!token || token == null) {
      token = this.cookieService.getObject('SynerAuth') as AuthToken;
      if (token) {
        this.remember = true;
      }
      else if ((!token || token == null) && this.tokenServerQuery) {
        token = this.buildTokenFromTokenServer();
      }      
    }
    return token;
  }

  setInLocalStorage(key, item) {
    localStorage.setItem(key, item);
  }

  getFromLocalStorage(key) {
    return localStorage.getItem(key);
  }

  removeFromLocalStorage(key) {
    localStorage.removeItem(key);
  }

  buildTokenServerQueryFromBase64QueryParameter() {
    const myUrl = new URL(window.location.href.replace(/#/g,""));
    const arrayQueryParams = myUrl.search.replace('?', '').split('&').map(x => x.split('=')).map(x => {
      const keyName = x[0];
      const val = x[1];
      let object = {};
      object[keyName] = val;
      return object;
    });

    const dictionaryParams = {}

    arrayQueryParams.forEach(function(x) {
        const key = Object.keys(x)[0];
        dictionaryParams[key] = x[key];
    });

    if (dictionaryParams && dictionaryParams["external"]) {
      const decodeBase64 = atob(decodeURIComponent(dictionaryParams["external"]));
      this.tokenServerQuery = JSON.parse(decodeBase64);
    }
    else {
      this.tokenServerQuery = null;
    }
  }

  buildTokenFromTokenServer(): AuthToken {
    const expirationDate = new Date();
    expirationDate.setSeconds(expirationDate.getSeconds() + this.tokenServerQuery.expires_in);

    const token: AuthToken = {
        access_token: this.tokenServerQuery.access_token,
        clientId: this.tokenServerQuery.clientId,
        expirationDate: expirationDate,
        expirationSeconds: this.tokenServerQuery.expires_in,
        expires_in: this.tokenServerQuery.expires_in,
        refresh_token: this.tokenServerQuery.refresh_token,
        token_type: this.tokenServerQuery.token_type,
        username: this.tokenServerQuery.username,
    }
    this.setToken(token, false);
    return token;
  }

  hasTokenServerQueryValue(): boolean {
    return this.tokenServerQuery && this.tokenServerQuery.value;
  }

  getTokenServerQueryValue(): any {
    const value =  this.tokenServerQuery.value;
    this.tokenServerQuery.value = null;
    return value;
  }
}

export class AuthToken {
  public access_token: string;
  public token_type: string;
  public expires_in: number;
  public refresh_token: string;
  public expirationDate: Date;
  public expirationSeconds: number;
  public username: string;
  public clientId: number;
}

export class LoginInfo {
  public grantType: string;
  public username: string;
  public password: string;
  public scope: string;
  public clientId: number;
  public remember: boolean;
}
