import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ProductComponent} from '@app/models/productComponent';
import {AppConfig} from '@app/models/app-config.model';

@Component({
  selector: 'app-cart-line-draw',
  templateUrl: './cart-line-edit-draw.component.html',
  styleUrls: ['./cart-line-edit-draw.component.scss']
})
export class CartLineEditDrawComponent implements OnChanges {


  @Input() layers: ProductComponent[];
  productLayersPath = this.appConfig.folder.productLayersFolder;
  svg = this.appConfig.images.svg;
  componentsPaths?: ComponentsPath[] = [];

  constructor(
    public appConfig: AppConfig) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.drawFullGlass();
  }

  drawFullGlass(layers?: ProductComponent[]) {
    this.componentsPaths = [];
    if (!layers) {
      layers = this.layers;
    }
    if (layers != null) {
      for (const component of layers) {
        const layer = this.productLayersPath + 'L' + this.svg;

        /*Couche extérieure*/
        if (!component.componentType.startsWith('G')) {
          if ((component.productType.trim().toString() !== 'V' || component.productType.trim().toString() !== 'VF')
            && component.coatingGlass && component.exteriorCoating) {
            this.componentsPaths.push({
              path: layer,
              component: {productType: 'L'}
            });
          }

          /*Vitre*/
          const vitre = this.productLayersPath + component.productType.trim() + this.svg;
          this.componentsPaths.push({
            path: vitre,
            component: component
          });

          /*Couche intérieure*/
          if ((component.productType.trim().toString() !== 'V' || component.productType.trim().toString() !== 'VF')
            && component.coatingGlass && !component.exteriorCoating) {
            this.componentsPaths.push({
              path: layer,
              component: {productType: 'L'}
            });
          }
        }
      }
    }
  }

}

export class ComponentsPath {
  component: ProductComponent;
  path: string;
}
